import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.hasHistory)
    ? _withDirectives((_openBlock(), _createBlock(_component_Button, _mergeProps({
        key: 0,
        icon: "pi pi-arrow-left",
        onClick: _ctx.back
      }, _ctx.$attrs), null, 16, ["onClick"])), [
        [
          _directive_tooltip,
          _ctx.$t('back'),
          void 0,
          { bottom: true }
        ]
      ])
    : (_openBlock(), _createBlock(_component_router_link, _normalizeProps(_mergeProps({ key: 1 }, _ctx.$attrs)), {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_Button, { icon: "pi pi-arrow-left" }, null, 512), [
            [
              _directive_tooltip,
              _ctx.$t('back'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        _: 1
      }, 16))
}