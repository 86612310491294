import { Shift } from '@/model/Shift';
import { HttpService } from './base/http.service';

class ShiftsService extends HttpService{
    readonly endPoint = `shifts`;
    
    public getAll(): Promise<Shift[]>  {
        return this.get<Shift[]>(`${this.endPoint}`);
    }
    
    public upsertAll(item: Shift[]): Promise<Shift[]>{
        return this.put<Shift[]>(`${this.endPoint}`, item);
    }
}

export const shiftsService = new ShiftsService();



