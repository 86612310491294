
  import { computed, defineComponent } from "vue";
  import { useRouter } from "vue-router";

  export default defineComponent({
    inheritAttrs: false,
    setup() {
      const r = useRouter();

      const hasHistory = computed(() => {
        return !!window.history.state.back; 
      });

      function back() {
        r.back();
      }

      return {
        hasHistory,
        back,
      };
    },
  });
