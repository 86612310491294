import { UserRoleEnum } from '@/model/User';
import { RouteRecordRaw } from 'vue-router';
import { ZonesRoutesEnum } from './ZonesRoutesEnum';

const ZonesPage = () => import(/* webpackChunkName: "Zones-module" */ '../pages/zones/ZonesPage.vue');
const ZonePage = () => import(/* webpackChunkName: "Zones-module" */ '../pages/zone/ZonePage.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/zones',
        name: ZonesRoutesEnum.ZONE_LIST,
        component: ZonesPage,
        meta: {
            tabTitle: 'Zone',
            breadCrumbItems: [
                { label: 'Elenco zone' }
            ],
            roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
        }
    },
    {
        path: '/zones/:zoneId',
        name: ZonesRoutesEnum.ZONE_DETAIL,
        props: true,
        component: ZonePage,
        meta: {
            tabTitle: 'Zona',
            breadCrumbItems: [
                { label: 'Elenco zone', to: { name: ZonesRoutesEnum.ZONE_LIST } },
                { label: 'Dettaglio zona' }
            ],
            roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
        }
    },
];

export { routes };
export { ZonesRoutesEnum };