
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      modelValue: {
        type: Number,
        required: true,
      },
      locale: {
        type: String,
        default: null
      },
      currency: {
        type: String,
        default: () => 'EUR'
      }
    },
    computed: {
      formatted() {
        if (typeof this.modelValue === "number") {
          const locale = (this.locale as string) || undefined; 
          return this.modelValue?.toLocaleString(
              locale, 
              {
                style: 'currency', 
                currency: (this.currency as string) || undefined
              }
            );
        } else {
            return "NaN";
        }
      },
    },
  });
