import { App } from 'vue';
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome';
import { library, dom }     from '@fortawesome/fontawesome-svg-core';

/*
    Abilito l'utilizzo delle icone senza il componente  

    Es: 
    <i class="fas fa-car" />
*/
dom.watch();

/* 
    Importo tutta la libreria affinché 
    le icone possano essere selezionate dinamicamente 
*/
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

export function initFontawesome(app: App){

    // Registro il componente FaIcon globalmente 
    app.component('FaIcon', FontAwesomeIcon);
}

/*
    Per sfruttare il TreeShaking e ridurre l'impatto della libreria
    sul peso dell'applicazione seleziono solamente le poche icone utilizzate.
    ---
    NB: TreeShaking disabilitato per abilitare l'utilizzo dinamico
*/
// import { 
//     faSpinner, 
//     faUserSecret,
//     faTruckMoving,
//     faMotorcycle,
//     faAddressBook,
//     faUsers,
//     faBuilding,
//     faCar,
//     faBoxOpen,
//     faEuroSign,
//     faPaintBrush,
//     faEraser,
//     faTrashAlt,
//     faTimes,
//     faMap,
//     faIdCard
// }   from '@fortawesome/free-solid-svg-icons';

// library.add(
//     faUserSecret,
//     faSpinner,
//     faUsers,
//     faTruckMoving,
//     faMotorcycle,
//     faAddressBook,
//     faBuilding,
//     faPaintBrush,
//     faEuroSign,
//     faCar,
//     faMotorcycle,
//     faTruckMoving,
//     faBoxOpen,
//     faEraser,
//     faTrashAlt,
//     faTimes,
//     faMap,
//     faIdCard
// );
