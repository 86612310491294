
  import { Options, Vue } from "vue-class-component";
  import { Prop } from "vue-property-decorator";

  export declare class ITableSkeleton {
    $props: {
      columns?: number;
      rows?: number;
    };
  }

  @Options({
    name: "TableSkeleton",
  })
  export default class TableSkeleton extends Vue {
    @Prop({ default: 5 })
    readonly columns!: number;

    @Prop({ default: 10 })
    readonly rows!: number;

    get fakeData() {
      return Array.from({ length: this.rows }).fill({});
    }
  }
