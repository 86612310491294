import { RouteRecordRaw }     from 'vue-router';
import { BadgesRoutesEnum } from './BadgesRoutesEnum';

const BadgesPage = () => import(
    /* webpackChunkName: "Badges-module" */
    '../pages/badges/BadgesPage.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path     : '/badges',
        name     : BadgesRoutesEnum.BADGES_LIST,
        component: BadgesPage,
        meta     : {
            tabTitle       : 'Tibrature',
            breadCrumbItems: [
                { label: 'Tibrature' },
            ]
        }
    },
];

export { routes };
export { BadgesRoutesEnum };
