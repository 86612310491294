import { Plugin } from 'vue';

enum LocalStorageEnum {
    ACCESS_TOKEN = "ACCESS_TOKEN"
}

class LocalStorageSVC {

    get token(): string {
        return this._get(LocalStorageEnum.ACCESS_TOKEN);
    }

    set token(token: string) {
        this._save(LocalStorageEnum.ACCESS_TOKEN, token);
    }

    clearToken() {
        localStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    }

    clearAll() {
        localStorage.clear();
        sessionStorage.clear();
    }

    private _save<T>(name: string, item: T) {
        localStorage.setItem(name, JSON.stringify(item));
    }

    private _get<T>(name: string) {
        const stringified = localStorage.getItem(name);

        return stringified
            ? JSON.parse(stringified) as T
            : null;
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        readonly $localStorage: LocalStorageSVC;
    }
}

const LocalStoragePlugin: Plugin = {
    install(app) {
        console.debug("Installing local-storage plugin...");

        app.config.globalProperties.$localStorage = localStorageSVC;
    }
};

export const localStorageSVC = new LocalStorageSVC();

export default LocalStoragePlugin;