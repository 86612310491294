export { };

declare global {
    interface String {
        /**
         * Capitalize the string
         * Eg:
         * string -> String
         */
        capitalize(): string;
    }
}

if (!String.prototype.capitalize) {
    String.prototype.capitalize = function(): string {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
}
