export enum BillingRoutesEnum{
    PREFIX = 'billing',

    BILLING_SUPPLIERS       = 'billing_suppliers',
    BILLING_SUPPLIERS_FIND  = 'billing_suppliers-find',
    BILLING_SUPPLIERS_NEW   = 'billing_suppliers-new',

    BILLING_CUSTOMERS       = 'billing_customers',
    BILLING_CUSTOMERS_FIND  = 'billing_customers-find',
    BILLING_CUSTOMERS_NEW   = 'billing_customers-new',
}