import { BaseApiModel } from "./common/BaseApiModel";
import { Order } from "./Order";
import { ShiftCost } from "./ShiftCost";
import { User } from "./User";
import { VehicleData } from "./Vehicle";
import { ZoneCustomPricing, ZoneRelation } from "./Zone";

export abstract class Entity {
  id?               : number;
  name?             : string;
  surname?          : string;
  business_name?    : string;
  business_address? : string;

  business_city?      : string;
  business_city_id?   : number;
  business_city_name? : string;

  business_province?    : string;
  business_state?       : string;
  business_postal_code? : string;
  business_tel?         : string;
  business_note?        : string;
  sdi?                  : string;
  pec?                  : string;
  email?                : string;
  vat?                  : string;
  fiscal_code?          : string;
  is_active?            : boolean = true;
  note?                 : string;

  type?         : UserType;
  payment_type? : PaymentType = PaymentType.ANTICIPATED;

  users?: User[] = [];

  perc_fee?            : number = 0;
  cost_avg_delivery?   : number = 0;
  is_time_cost_active? : boolean = false;
  is_ignore_cost_zone? : boolean = false;
  is_perc_fee_active?  : boolean = false;

  zones?               : ZoneRelation[];
  zone_custom_pricing? : ZoneCustomPricing[];

  pickers? : EntityPicker[];

  vehicle_data : VehicleData[];
}
export abstract class EntityWithCosts extends Entity {
  shift_costs : ShiftCost[];
}

export enum UserType {
  SUPPLIER = 10,
  CUSTOMER = 20,
  TAKER    = 30
}

export enum PaymentType {
  ANTICIPATED = 10,
  POSTPONED   = 20
}

export enum EntityPickerType {
  PICKUP  = 10,
  DROPOFF = 20
}

export class EntityPicker implements BaseApiModel {
  id?          : number;
  entity_id?   : number;
  type?        : EntityPickerType;
  name         : string = "";
  address?     : string;
  city_name?   : string;
  city_id?     : number;
  province?    : string;
  state?       : string;
  postal_code? : string;
  note?        : string;
  lat?         : number;
  lon?         : number;
  phone?       : string;

  constructor(data?: EntityPicker) {
    if (data) {
      Object.assign(this, data);
    }
  }

}

type KeysFromOrder = (keyof Omit<PickerSummaryModel, 'type'>)[];
export class PickerSummaryModel {
  type         : EntityPickerType;
  picker_id    : number;
  picker_name  : string;
  full_address : string;
  phone        : string;
  note         : string;
  lat          : number;
  lon          : number;

  static fromOrder(order: Order, type: EntityPickerType) {
    const key_prefix = type === EntityPickerType.PICKUP
      ? "pickup_"
      :  "dropoff_";
      
    const keys: KeysFromOrder = [
      "picker_id", "picker_name", "full_address", 
      "note", "lat", "lon", "phone"
    ];

    const p = keys.reduce(
      (x, k) => {
        (x[k] as string | number) = order[`${key_prefix}${k}`];
        return x;
      },
      {} as PickerSummaryModel
    );

    p.type = type;

    return p; 
  }

  static fromEntity(e: EntityPicker) {
    const ps = new PickerSummaryModel(); 

    ps.type         = e.type;
    ps.picker_id    = e?.id;
    ps.picker_name  = e?.name;
    ps.full_address = e?.address;
    ps.phone        = e?.phone;
    ps.note         = e?.note;

    return ps;
  }
}