
  import { configuration } from "@plugins/Config-plugin";
  import moment, { Moment } from "moment";
  import { Options, Vue } from "vue-class-component";
  import { Prop } from "vue-property-decorator";

  @Options({})
  export default class DateFormatted extends Vue {
    @Prop() readonly date!: Date | Moment;

    @Prop({
      default: configuration.dateFormat,
    })
    readonly format!: String;

    get formattedDate() {
      let m = moment(this.date); 

      return m.isValid() 
        ? m.format((this.format as string))
        : null; 
    }
  }
