import { defineRule } from 'vee-validate';

import { confirmed, email, min, required } from '@vee-validate/rules';

import { checkComplexityPassword } from '@sharedUtils/utils';

defineRule('required', (x : any) => {
  if (required(x)) {
    return true;
  } else {
    return "validation.required";
  }
});

defineRule('email', (x : any) => {
  if (email(x)) {
    return true;
  } else {
    return "validation.email";
  }
});

defineRule('min', (x: string, y: any) => {
  if (min(x, y)) {
    return true;
  } else {
    return "validation.min_length";
  }
});

defineRule('confirmPwd', (x: string, y: any) => {
  if (confirmed(x, y)) {
    return true;
  } else {
    return "validation.confirm_pwd";
  }
});

defineRule('complexityPwd', (x: string, y: number) => {
  if (checkComplexityPassword(x, +y[0])) {
    return true;
  } else {
    return "validation.complexity_pwd";
  }
});

defineRule( 'requiredThisOr', ( value: any, [ otherField ]: string[], { form } ) => {
  const y = form[otherField];

  if ((value != null || y != null) && (!!value || !!y)) {
    return true;
  } else {
    return `validation.required_this_or_${ otherField }`;
  }
} );
/*
// ALL RULES
Object.keys(rules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});
*/
