<template>

  <span class="p-float-label">
    <slot />

    <label
      :for="$attrs.for?.toString()"
      :class="$attrs.labelClass"
    >
      {{$attrs.label}}
    </label>
  </span>

</template>