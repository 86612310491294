import OverlayPanel from "primevue/overlaypanel";
import { Options, Vue } from "vue-class-component";

@Options({
  name: 'InfoIcon',
})
export default class InfoIcon extends Vue {
  toggle(event: MouseEvent) {
    (this.$refs.infoPanel as OverlayPanel).toggle(event);
  }
}
