import { RouteRecordRaw } from 'vue-router';
import { AuthRoutesEnum } from './AuthRoutesEnum';

const LoginPage         = () => import(/* webpackChunkName: "auth-module" */ '../pages/Login/LoginPage.vue');
const RegistrationPage  = () => import(/* webpackChunkName: "auth-module" */ '../pages/Registration/RegistrationPage.vue');
const LostPasswordPage  = () => import(/* webpackChunkName: "auth-module" */ '../pages/LostPassword/LostPasswordPage.vue');
const ResetPasswordPage = () => import(/* webpackChunkName: "auth-module" */ '../pages/ResetPassword/ResetPasswordPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/signin',
        alias: ['/login', '/sign-in'],
        name: AuthRoutesEnum.SIGN_IN,
        component: LoginPage,
        meta: {
            tabTitle: 'Login',
            requiresGuest: true
        }
    },
    {
        path: '/signup',
        alias: ['/register', '/sign-up'],
        name: AuthRoutesEnum.SIGN_UP,
        component: RegistrationPage,
        meta: {
            tabTitle: 'Registrati',
            requiresGuest: true
        }
    },
    {
        path: '/forgot-password',
        name: AuthRoutesEnum.FORGOTTEN_PWD,
        component: LostPasswordPage,
        meta: {
            tabTitle: 'Password dimenticata',
            requiresGuest: true
        }
    },
    {
        path: '/reset-password/:token',
        name: AuthRoutesEnum.RESET_PWD,
        component: ResetPasswordPage,
        props: true,
        meta: {
            tabTitle: 'Reset Password',
            requiresGuest: true
        }
    }
];

export { routes };
export { AuthRoutesEnum };