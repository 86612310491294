import { RouteRecordRaw }     from 'vue-router';
import { MessagesRoutesEnum } from './MessagesRoutesEnum';

const MessagesPage = () => import(
    /* webpackChunkName: "Messages-module" */
    '../pages/messages/MessagesPage.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path     : '/messages',
        name     : MessagesRoutesEnum.MESSAGES,
        component: MessagesPage,
        meta     : {
            tabTitle       : 'Messaggi',
            breadCrumbItems: [
                { label: 'Messaggi' },
                /*{label: 'Dettaglio cliente', to: { name: CustomersRoutesEnum.CUSTOMERS_LIST }},*/
            ]
        }
    },
];

export { routes };
export { MessagesRoutesEnum };
