import { Customer }     from '@/model/Customer';
import { EntityPicker } from '@/model/Entity';
import { NewOrder }     from '@/model/Order';
import { Vehicle } from '@/model/Vehicle';
import { Zone } from '@/model/Zone';
import { authStore } from '@/modules/auth/store';
import { plainToClass } from 'class-transformer';
import { MutationTree } from 'vuex';
import { OrdersState }  from './state';

export interface Mutations {
    /**
     * Inizializza *newOrder* 
     * - se è salvato in localStorage lo ricarica
     * - altrimenti lo inizializza *new NewOrder()*
     * @param forceNew se true inizializza sempre un nuovo oggetto
     */
    initNewOrder(forceNew?: boolean): void; 

    /**
     * Aggiorna il valore di tutte le chiavi corrispondenti passate
     * tramite il parametro order
     */
    updateOrder(order: Partial<NewOrder>): void;

    /**
     * Sostituisce completamente l'ordine con quello passato
     * @param order 
     */
    setOrder(order: NewOrder): void;
    
    /**
     * Setta il valore della zona con quella passata da parametro  
     * ed effettua il reset di:
     * - Customer
     * - Pickup
     * - Dropoff
     * @param zone Nuova zona
     */
    setZone(zone: Zone): void;

    /**
     * Setta il valore del cliente con quello passato da parametro  
     * ed effettua il reset di:
     * - Pickup
     * - Dropoff
     * @param customer Nuovo cliente
     */
    setCustomer(customer: Customer): void;
    
    /**
     * Setta il valore del pickup con quello passato da parametro  
     * @param picker Nuovo Pickup
     */
    setPickerPickup(picker: EntityPicker): void; 
    setSaveNewPickup(flag: boolean): void;
    
    /**
     * Setta il valore del dropoff con quello passato da parametro
     * @param picker Nuovo Dropoff
     */
    setPickerDropoff(picker: EntityPicker): void; 
    setSaveNewDropoff(flag: boolean): void;

    setExternalCode(value: string): void; 
    setVehicle(vehicle: Vehicle): void; 
}

function getOrderFromStorage() {
    const stringObj = localStorage.getItem('new_order')
    return stringObj && plainToClass(
        NewOrder, 
        JSON.parse(stringObj), {
        targetMaps: [
            {
                target: NewOrder,
                properties: {
                    _zone: Zone,
                    pickup_datetime  : Date,
                    dropoff_datetime : Date
                }
            }
        ]
    })
}


const mutations: MutationTree<OrdersState> = {
    
    initNewOrder(state, forceNew: boolean = false) {
        state.newOrder = (!forceNew && getOrderFromStorage()) || new NewOrder(); 
    },

    setOrder(state, order: NewOrder) {
        state.newOrder = order;
    },

    updateOrder(state, newOrder: NewOrder) {
        state.newOrder = plainToClass(NewOrder, {
                ...state.newOrder,
                ...newOrder
            }
        )
    },

    setZone(state, zone: Zone) {
        state.newOrder.zone = zone;

        if (authStore.getters.me?.entity_id !== state.newOrder?.customer?.id) {
            state.newOrder.resetCustomer();
        }
        
        state.newOrder.resetPickup();
        state.newOrder.resetDropoff();
    },

    setCustomer(state, customer: Customer) {
        state.newOrder.customer = customer;

        state.newOrder.resetPickup();
        state.newOrder.resetDropoff();
    },

    setPickerPickup(state, picker: EntityPicker) {
        if (picker.id) {
            state.newOrder.is_save_new_pickup = false;
        }

        state.newOrder.pickup_picker_id   = picker.id;
        state.newOrder.pickup_picker      = picker;
    },

    setSaveNewPickup({newOrder}, save: boolean){
        newOrder.is_save_new_pickup = save;
    },

    setPickerDropoff(state, picker: EntityPicker) {

        if (picker.id) {
            state.newOrder.is_save_new_dropoff = false;
        }
        
        state.newOrder.dropoff_picker_id   = picker.id;
        state.newOrder.dropoff_picker      = picker;
    },

    setSaveNewDropoff({newOrder}, save: boolean){
        newOrder.is_save_new_dropoff = save;
    },

    setExternalCode({newOrder}, value: string){
        newOrder.external_client_code = value; 
    },

    setVehicle({newOrder}, vehicle: Vehicle){
        newOrder.vehicle = vehicle; 
    }
}

export default mutations;