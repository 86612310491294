import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

@Options({})
export default class TimeStepper extends Vue {
  @Prop() min: string;
  @Prop() max: string;

  value: string = null;

  @Model('time') timeModel: Date;

  @Watch('timeModel', { immediate: true })
  onTimeModelChange() {
    if(this.timeModel)
    this.value = moment(this.timeModel).format("HH:mm")
  }

  onChange(e: InputEvent) {
    const hhmm = (e.target as HTMLInputElement).value;

    if (this.min || this.max) {
      const newValue  = +(hhmm.concat(":00").replaceAll(':',''));
      const min       = this.min && +(this.min.replaceAll(':', ''));
      const max       = this.max && +(this.max.replaceAll(':', ''));
      
      if((min&&min > newValue) || (max&&max < newValue)) {
        this.timeModel = moment(this.timeModel).toDate();
        return;
      }
    }

    const [hh,mm] = hhmm.split(":");
    this.updateTimeModel(+hh, +mm);
  }

  private updateTimeModel(h:number, m: number) {
    this.timeModel = moment(this.timeModel)
                      .hours(h)
                      .minutes(m)
                      .toDate();
  }

  mounted() {
    this.timeModel ??= new Date()
  }
}