import { configuration }                                                       from "@/plugins/Config-plugin";
import { localStorageSVC }                                                     from "@/plugins/LocalStorage-plugin";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { $pusher }                                                             from "@/pusher";

export class HttpService {

    public http: AxiosInstance;

    constructor( apiBaseUrl?: string ) {
        this.http = axios.create( {
            baseURL: apiBaseUrl || this._getApiBaseUrl(),
            headers: {
                'Accept': 'application/json'
            }
        });

        this._addInterceptorRequest();
    }

    private _getApiBaseUrl() {
        const apiBaseUrl = configuration.apiBaseUrl;

        if (!apiBaseUrl || apiBaseUrl === '') {
            throw Error("Api base URL required");
        }

        return apiBaseUrl;
    }


    private _addInterceptorRequest() {
        this.http.interceptors.request.use(
            (config: AxiosRequestConfig) => {

                config.params = {
                    ...config.params,
                }

                if (localStorageSVC.token) {
                    config.headers['Authorization'] = `Bearer ${ localStorageSVC.token }`;
                    config.headers['X-Socket-ID']   = $pusher.value?.connection?.socket_id;
                }

                return config;
            }
        );

        this.http.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: AxiosError) => {
                return Promise.reject(error);
            }
        );
    }

    async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = this.http.post<T>(url, data, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        const response = this.http.put<T>(url, data, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async patch<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        const response = this.http.patch<T>(url, data, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = this.http.get<T>(url, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async delete<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = this.http.delete<T>(url, {
            ...config, data
        });

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    private _handleSuccess(response: AxiosResponse): Promise<any> {
        return response.data;
    }

    private _handleError(error: AxiosError): Promise<any> {
        return Promise.reject(error.response);
    }

}
