import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-732c1d36")
const _hoisted_1 = { class: "date_range" }
const _hoisted_2 = { class: "date_range__overlay" }
const _hoisted_3 = { class: "p-text-center" }
const _hoisted_4 = { class: "p-d-flex p-jc-center p-my-2" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      icon: "pi pi-calendar",
      onClick: _ctx.openCalendar
    }, null, 8, ["onClick"]),
    _createElementVNode("span", {
      class: "date_range__text",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCalendar && _ctx.openCalendar(...args)))
    }, _toDisplayString(_ctx.$t('common.date_range', { fromDate: _ctx.fromDate, toDate: _ctx.toDate })), 1),
    _createVNode(_component_OverlayPanel, {
      ref: "op",
      appendTo: "body"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, _toDisplayString(_ctx.$t('common.date_range', { fromDate: _ctx.fromDate, toDate: _ctx.toDate })), 1),
          _createVNode(_component_Calendar, {
            modelValue: _ctx.fromTo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.fromTo = $event)),
            selectionMode: "range",
            showSeconds: false,
            inline: true,
            numberOfMonths: 2
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              label: "Reset",
              icon: "pi pi-times",
              class: "p-button-info",
              onClick: _ctx.onReset
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              label: "Applica",
              icon: "pi pi-check",
              class: "p-ml-3",
              onClick: _ctx.onApply
            }, null, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}