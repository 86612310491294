/**
 * Log semantic version of the APP. 
 * You can modify the version in the package.json file.
 * The version is copied in process.env in vue.config file.
 */
 export function logAppVersion() {
    console.info(
        `%cVERSION`,
        "background-color:#4aa935; color:white; padding: 0 3rem; font-weight: bold;",
        process.env.VUE_APP_VERSION
    );
}