import { App, reactive } from 'vue';

// COMPONENTS
import PrimeVue              from 'primevue/config';
import AutoComplete          from 'primevue/autocomplete';
import Accordion             from 'primevue/accordion';
import AccordionTab          from 'primevue/accordiontab';
import Avatar                from 'primevue/avatar';
import AvatarGroup           from 'primevue/avatargroup';
import Badge                 from 'primevue/badge';
import Button                from 'primevue/button';
import Breadcrumb            from 'primevue/breadcrumb';
import Calendar              from 'primevue/calendar';
import Card                  from 'primevue/card';
import CascadeSelect         from 'primevue/cascadeselect';
import Carousel              from 'primevue/carousel';
import Chart                 from 'primevue/chart';
import Checkbox              from 'primevue/checkbox';
import Chip                  from 'primevue/chip';
import Chips                 from 'primevue/chips';
import ColorPicker           from 'primevue/colorpicker';
import Column                from 'primevue/column';
import ColumnGroup           from 'primevue/columngroup';
import ConfirmDialog         from 'primevue/confirmdialog';
import ConfirmPopup          from 'primevue/confirmpopup';
import ContextMenu           from 'primevue/contextmenu';
// import DataTable             from '../../.mp_primevue/datatable/DataTable.vue';
import DataTable             from 'primevue/datatable';
import DataView              from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog                from 'primevue/dialog';
import Divider               from 'primevue/divider';
import Dropdown              from 'primevue/dropdown';
import Fieldset              from 'primevue/fieldset';
import FileUpload            from 'primevue/fileupload';
import FullCalendar          from 'primevue/fullcalendar';
import Galleria              from 'primevue/galleria';
import Image                 from 'primevue/image';
import InlineMessage         from 'primevue/inlinemessage';
import Inplace               from 'primevue/inplace';
import InputSwitch           from 'primevue/inputswitch';
import InputText             from 'primevue/inputtext';
import InputMask             from 'primevue/inputmask';
import InputNumber           from 'primevue/inputnumber';
import Knob                  from 'primevue/knob';
import Listbox               from 'primevue/listbox';
import MegaMenu              from 'primevue/megamenu';
import Menu                  from 'primevue/menu';
import Menubar               from 'primevue/menubar';
import Message               from 'primevue/message';
import MultiSelect           from 'primevue/multiselect';
import OrderList             from 'primevue/orderlist';
import OrganizationChart     from 'primevue/organizationchart';
import OverlayPanel          from 'primevue/overlaypanel';
import Paginator             from 'primevue/paginator';
import Panel                 from 'primevue/panel';
import PanelMenu             from 'primevue/panelmenu';
import Password              from 'primevue/password';
import PickList              from 'primevue/picklist';
import ProgressBar           from 'primevue/progressbar';
import ProgressSpinner       from 'primevue/progressspinner';
import Rating                from 'primevue/rating';
import RadioButton           from 'primevue/radiobutton';
import Row                   from 'primevue/row';
import SelectButton          from 'primevue/selectbutton';
import ScrollPanel           from 'primevue/scrollpanel';
import ScrollTop             from 'primevue/scrolltop';
import Skeleton              from 'primevue/skeleton';
import Slider                from 'primevue/slider';
import Sidebar               from 'primevue/sidebar';
import SplitButton           from 'primevue/splitbutton';
import Splitter              from 'primevue/splitter';
import SplitterPanel         from 'primevue/splitterpanel';
import Steps                 from 'primevue/steps';
import TabMenu               from 'primevue/tabmenu';
import TieredMenu            from 'primevue/tieredmenu';
import Textarea              from 'primevue/textarea';
import Toast                 from 'primevue/toast';
import Toolbar               from 'primevue/toolbar';
import TabView               from 'primevue/tabview';
import TabPanel              from 'primevue/tabpanel';
import Tag                   from 'primevue/tag';
import Timeline              from 'primevue/timeline';
import ToggleButton          from 'primevue/togglebutton';
import Tree                  from 'primevue/tree';
import TreeTable             from 'primevue/treetable';
import TriStateCheckbox      from 'primevue/tristatecheckbox';
import VirtualScroller       from "primevue/virtualscroller";

// SERVICES
import ToastService        from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

// DIRECTIVES
import BadgeDirective from "primevue/badgedirective";
import Ripple         from 'primevue/ripple';
import Tooltip        from 'primevue/tooltip';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';

export const it_locale = {
    startsWith         : 'Inizia per',
    contains           : 'Contiene',
    notContains        : 'Non contiene',
    endsWith           : 'Finisce per',
    equals             : 'Uguale a',
    notEquals          : 'Diverso da',
    noFilter           : 'Nessun filtro',
    lt                 : 'Minore di',
    lte                : 'Minore o uguale di',
    gt                 : 'Maggiore di',
    gte                : 'Maggiore o uguale di',
    dateIs             : 'La data è',
    dateIsNot          : 'La data non è',
    dateBefore         : 'La data è prima di',
    dateAfter          : 'La data è dopo di',
    clear              : 'Pulisci',
    apply              : 'Applica',
    matchAll           : 'Corrispondenza completa',
    matchAny           : 'Corrispondenza parziale',
    addRule            : 'Aggiungi regola',
    removeRule         : 'Rimuovi regola',
    accept             : 'Si',
    reject             : 'No',
    choose             : 'Scegli',
    upload             : 'Carica',
    cancel             : 'Annulla',
    dayNames           : ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    dayNamesShort      : ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin        : ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
    monthNames         : ["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"],
    monthNamesShort    : ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    today              : 'Oggi',
    weekHeader         : 'Set',
    firstDayOfWeek     : 1,
    dateFormat         : 'dd/mm/yy',
    weak               : 'Debole',
    medium             : 'Medio',
    strong             : 'Forte',
    passwordPrompt     : 'Inserisci una password',
    emptyFilterMessage : 'Nessun risultato trovato',
    emptyMessage       : 'Nessuna opzione disponibile'
}

export const en_locale = {
    startsWith         : 'Starts with',
    contains           : 'Contains',
    notContains        : 'Not contains',
    endsWith           : 'Ends with',
    equals             : 'Equals',
    notEquals          : 'Not equals',
    noFilter           : 'No Filter',
    lt                 : 'Less than',
    lte                : 'Less than or equal to',
    gt                 : 'Greater than',
    gte                : 'Greater than or equal to',
    dateIs             : 'Date is',
    dateIsNot          : 'Date is not',
    dateBefore         : 'Date is before',
    dateAfter          : 'Date is after',
    clear              : 'Clear',
    apply              : 'Apply',
    matchAll           : 'Match All',
    matchAny           : 'Match Any',
    addRule            : 'Add Rule',
    removeRule         : 'Remove Rule',
    accept             : 'Yes',
    reject             : 'No',
    choose             : 'Choose',
    upload             : 'Upload',
    cancel             : 'Cancel',
    dayNames           : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort      : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin        : ["Su","Mo","Tu","We","Th","Fr","Sa"],
    monthNames         : ["January","February","March","April","May","June","July","August","September","October","November","December"],
    monthNamesShort    : ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today              : 'Today',
    weekHeader         : 'Wk',
    firstDayOfWeek     : 0,
    dateFormat         : 'mm/dd/yy',
    weak               : 'Weak',
    medium             : 'Medium',
    strong             : 'Strong',
    passwordPrompt     : 'Enter a password',
    emptyFilterMessage : 'No results found',
    emptyMessage       : 'No available options'
}


export function initPrimeVue(app: App) {

    app.config.globalProperties.$appState = reactive( {
        inputStyle: 'outlined',
        locale    : en_locale
    } );

    app.use( PrimeVue, { ripple: true } );
    app.use( ToastService );
    app.use( ConfirmationService );

    app.directive( 'badge', BadgeDirective );
    app.directive( 'ripple', Ripple );
    app.directive('tooltip',    Tooltip);

    app.component('Accordion',          Accordion);
    app.component('AccordionTab',       AccordionTab);
    app.component('AutoComplete',       AutoComplete);
    app.component('Avatar',             Avatar);
    app.component('AvatarGroup',        AvatarGroup);
    app.component('Badge',              Badge);
    app.component('Breadcrumb',         Breadcrumb);
    app.component('Button',             Button);
    app.component('Calendar',           Calendar);
    app.component('Card',               Card);
    app.component('Carousel',           Carousel);
    app.component('CascadeSelect',      CascadeSelect);
    app.component('Chart',              Chart);
    app.component('Checkbox',           Checkbox);
    app.component('Chip',               Chip);
    app.component('Chips',              Chips);
    app.component('ColorPicker',        ColorPicker);
    app.component('Column',             Column);
    app.component('ColumnGroup',        ColumnGroup);
    app.component('ConfirmDialog',      ConfirmDialog);
    app.component('ConfirmPopup',       ConfirmPopup);
    app.component('ContextMenu',        ContextMenu);
    app.component('DataTable',          DataTable);
    app.component('DataView',           DataView);
    app.component('PDialog',            Dialog);
    app.component('Divider',            Divider);
    app.component('Dropdown',           Dropdown);
    app.component('Fieldset',           Fieldset);
    app.component('FileUpload',         FileUpload);
    app.component('FullCalendar',       FullCalendar);
    app.component('InlineMessage',      InlineMessage);
    app.component('Image',              Image);
    app.component('Inplace',            Inplace);
    app.component('InputMask',          InputMask);
    app.component('InputNumber',        InputNumber);
    app.component('InputSwitch',        InputSwitch);
    app.component('InputText',          InputText);
    app.component('Galleria',           Galleria);
    app.component('Knob',               Knob);
    app.component('Listbox',            Listbox);
    app.component('MegaMenu',           MegaMenu);
    app.component('Menu',               Menu);
    app.component('Menubar',            Menubar);
    app.component('Message',            Message);
    app.component('MultiSelect',        MultiSelect);
    app.component('OrderList',          OrderList);
    app.component('OverlayPanel',       OverlayPanel);
    app.component('Paginator',          Paginator);
    app.component('Panel',              Panel);
    app.component('PanelMenu',          PanelMenu);
    app.component('Password',           Password);
    app.component('PickList',           PickList);
    app.component('ProgressBar',        ProgressBar);
    app.component('ProgressSpinner',    ProgressSpinner);
    app.component('RadioButton',        RadioButton);
    app.component('Rating',             Rating);
    app.component('Row',                Row);
    app.component('SelectButton',       SelectButton);
    app.component('ScrollPanel',        ScrollPanel);
    app.component('ScrollTop',          ScrollTop);
    app.component('Slider',             Slider);
    app.component('Sidebar',            Sidebar);
    app.component('Skeleton',           Skeleton);
    app.component('SplitButton',        SplitButton);
    app.component('Splitter',           Splitter);
    app.component('SplitterPanel',      SplitterPanel);
    app.component('Steps',              Steps);
    app.component('TabMenu',            TabMenu);
    app.component('TabView',            TabView);
    app.component('TabPanel',           TabPanel);
    app.component('Tag',                Tag);
    app.component('Textarea',           Textarea);
    app.component('TieredMenu',         TieredMenu);
    app.component('Timeline',           Timeline);
    app.component('Toast',              Toast);
    app.component('Toolbar',            Toolbar);
    app.component('ToggleButton',       ToggleButton);
    app.component('Tree',               Tree);
    app.component('TreeTable',          TreeTable);
    app.component( 'TriStateCheckbox', TriStateCheckbox );
    app.component( 'VirtualScroller ', VirtualScroller );
    app.component( 'OrganizationChart', OrganizationChart );
    app.component('DataViewLayoutOptions',  DataViewLayoutOptions);
}

type BoolFn = () => boolean;
export interface MenuItem {
    label     ?: string;
    icon      ?: string;
    to        ?: string | any;
    command   ?: Function;
    url       ?: string;
    items     ?: Array<any>;
    disabled  ?: boolean | BoolFn;
    visible   ?: boolean | Function;
    target    ?: string;
    separator ?: boolean;
    style     ?: Object;
    class     ?: string | Function;
    key       ?: string;
}
