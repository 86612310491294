import { App, Plugin } from 'vue';

import { ModuleOptions } from '@/modules/common/ModuleOptions';

import { OrdersStoreModule } from './store';
import { routes, standAloneRoutes } from './router';

export const OrdersModule: Plugin = {
    install(app: App<Element>, { router, parentRoute, store, storeName }: ModuleOptions) {
        console.groupCollapsed('ORDERS_MODULE: ADDING ROUTES');

        if (parentRoute) {
            routes.forEach((route) => {
                console.debug("Adding route to %s", parentRoute, route);
                router.addRoute(parentRoute, route);
            });
        } else {
            routes.forEach((route) => {
                console.debug("Adding route", route);
                router.addRoute(route);
            });
        }

        standAloneRoutes.forEach((route) => {
            console.debug("Adding standalone route", route);
            router.addRoute(route);
        });
        
        console.debug("After:", router.getRoutes());
        console.groupEnd();

        store.registerModule(
            storeName || 'orders',
            OrdersStoreModule
        )
    }
};
