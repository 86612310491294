
import { UserRoleEnum } from '@/model/User';
import store from '@/store';

import { ModulesEnum } from '@/store/modules';
import {
	NavigationGuardNext,
	RouteLocationNormalized,
	RouteRecordRaw
} from 'vue-router';

import { OrdersRoutesEnum } from './OrdersRoutesEnum';

const OrdersImportPage = () => import(
	/* webpackChunkName: "Orders-import-module" */
	'../pages/orders-import/OrdersImportPage.vue'
);

const OrdersPage = () => import(
	/* webpackChunkName: "Orders-module" */
	'../pages/orders/OrdersPage.vue'
);

const OrdersAsCustomerPage = () => import(
	/* webpackChunkName: "Orders-module" */
	'../pages/orders-as-customer/OrdersAsCustomerPage.vue'
);

const OrdersAsSupplierPage = () => import(
	/* webpackChunkName: "Orders-module" */
	'../pages/orders-as-supplier/OrdersAsSupplierPage.vue'
);

const OrderPage = () => import(
	/* webpackChunkName: "Orders-module" */
	'../pages/order/OrderPage.vue'
);

const NewOrderPage = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderPage.vue'
);
const NewOrderZone = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderZone/NewOrderZone.vue'
);
const NewOrderCustomer = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderCustomer/NewOrderCustomer.vue'
);
const NewOrderPickup = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderPickup/NewOrderPickup.vue'
);
const NewOrderDropoff = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderDropoff/NewOrderDropoff.vue'
);
const NewOrderData = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderData/NewOrderData.vue'
);
const NewOrderSummary = () => import(
	/* webpackChunkName: "Orders-new-module" */
	'../pages/new-order/NewOrderSummary/NewOrderSummary.vue'
);


const OrderPaymentSuccessPage = () => import(
	/* webpackChunkName: "Orders-payment-success" */
	'../pages/order-payment-success/OrderPaymentSuccessPage.vue'
);
const OrderPaymentCancelPage = () => import(
	/* webpackChunkName: "Orders-payment-cancel" */
	'../pages/order-payment-cancel/OrderPaymentCancelPage.vue'
);

/**
 * Flusso lineare creazione ordine,
 * utile per individuare la direzione della navigazione
 */
const steps = [
	OrdersRoutesEnum.ORDERS_NEW_ZONE,
	OrdersRoutesEnum.ORDERS_NEW_CUSTOMER,
	OrdersRoutesEnum.ORDERS_NEW_PICKUP,
	OrdersRoutesEnum.ORDERS_NEW_DROPOFF,
	OrdersRoutesEnum.ORDERS_NEW_DATA,
	OrdersRoutesEnum.ORDERS_NEW_COMPLETE,
	OrdersRoutesEnum.ORDERS_PAYMENT_SUCCESS,
];

/**
 * Setta il nome dell'animazione in base alla direzione della navigazione
 * @param to : destinazione
 * @param from : provenienza
 * @param next : procedi()
 */
const transitionGuard = function (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) {
	const fromIdx = steps.indexOf(from.name as OrdersRoutesEnum);
	const toIdx = steps.indexOf(to.name as OrdersRoutesEnum);

	if (toIdx > fromIdx) {
		to.meta.transitionName = "slide"
	} else {
		to.meta.transitionName = "slide-reverse"
	}

	next();
}

const routes: Array<RouteRecordRaw> = [
	{
		path      : 'orders-import',
		name      : OrdersRoutesEnum.ORDERS_IMPORT,
		component : OrdersImportPage,
		meta: {
			tabTitle   : "Import Ordini",
			breadCrumbItems : [
				{ label: 'Import Ordini' }
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
	{
		path      : 'admin/orders',
		name      : OrdersRoutesEnum.ORDERS_LIST,
		component : OrdersPage,

		beforeEnter(
			to   : RouteLocationNormalized,
			from : RouteLocationNormalized,
			next : NavigationGuardNext
		) {
			const role = store.getters[`${ModulesEnum.AUTH}/userRole`];

			switch (role) {
				case UserRoleEnum.SUPER_ADMIN:
				case UserRoleEnum.TMT_OPERATOR:
					next();
					break;

				case UserRoleEnum.CUSTOMER_ADMIN:
				case UserRoleEnum.CUSTOMER_USER:
					next({name: OrdersRoutesEnum.ORDERS_LIST_AS_CUSTOMER});
					break;

				case UserRoleEnum.SUPPLIER_ADMIN:
				case UserRoleEnum.SUPPLIER_USER:
					next({name: OrdersRoutesEnum.ORDERS_LIST_AS_SUPPLIER});
					break;

				default:
					next();
					break;
			}
		},
		meta: {
			tabTitle: 'Ordini',
			breadCrumbItems: [
				{ label: 'Elenco ordini' },
			]
		}
	},
	{
		path: 'customer/orders',
		name: OrdersRoutesEnum.ORDERS_LIST_AS_CUSTOMER,
		component: OrdersAsCustomerPage,
		meta: {
			tabTitle: 'Ordini',
			breadCrumbItems: [
				{ label: 'Elenco ordini' },
			],
			roles: [UserRoleEnum.CUSTOMER_ADMIN, UserRoleEnum.CUSTOMER_USER]
		}
	},
	{
		path: 'supplier/orders',
		name: OrdersRoutesEnum.ORDERS_LIST_AS_SUPPLIER,
		component: OrdersAsSupplierPage,
		meta: {
			tabTitle: 'Ordini',
			breadCrumbItems: [
				{ label: 'Elenco ordini' },
			],
			roles: [UserRoleEnum.SUPPLIER_ADMIN, UserRoleEnum.SUPPLIER_USER]
		}
	},
	{
		path: '/orders/:orderId(\\d+)',
		name: OrdersRoutesEnum.ORDERS_DETAIL,
		props: true,
		component: OrderPage,
		meta: {
			tabTitle: 'Dettaglio ordine',
			breadCrumbItems: [
				{ label: 'Elenco ordini', to: { name: OrdersRoutesEnum.ORDERS_LIST } },
				{ label: 'Dettaglio ordine' },
			]
		}
	},
	{
		path: '/orders/new',
		props: true,
		component: NewOrderPage,
		children: [
			{
				path        : 'zoneStep',
				name        : OrdersRoutesEnum.ORDERS_NEW_ZONE,
				component   : NewOrderZone,
				beforeEnter : transitionGuard,
			},
			{
				path        : 'customerStep',
				name        : OrdersRoutesEnum.ORDERS_NEW_CUSTOMER,
				component   : NewOrderCustomer,
				beforeEnter : transitionGuard,
			},
			{
				path        : 'pickupStep',
				name        : OrdersRoutesEnum.ORDERS_NEW_PICKUP,
				component   : NewOrderPickup,
				beforeEnter : transitionGuard,
			},
			{
				path        : 'dropoffStep',
				name        : OrdersRoutesEnum.ORDERS_NEW_DROPOFF,
				component   : NewOrderDropoff,
				beforeEnter : transitionGuard,
			},
			{
				path        : 'orderStep',
				name        : OrdersRoutesEnum.ORDERS_NEW_DATA,
				component   : NewOrderData,
				beforeEnter : transitionGuard,
			},
			{
				path        : 'lastStep',
				name        : OrdersRoutesEnum.ORDERS_NEW_COMPLETE,
				component   : NewOrderSummary,
				beforeEnter : transitionGuard,

			},
		],

		meta: {
			tabTitle: 'Nuovo ordine',
			breadCrumbItems: [
				{ label: 'Elenco ordini', to: { name: OrdersRoutesEnum.ORDERS_LIST } },
				{ label: 'Riepilogo ordine' },
			]
		}
	}
];


function existingPaymentInProgress(to, __, next) {
	const idFromStorage = localStorage.getItem('payment_order');
	const idFromRoute = to.params?.orderId;

	if (idFromStorage === idFromRoute){
		next(true)
	} else {
		next({name: OrdersRoutesEnum.ORDERS_LIST})
	}
}

const standAloneRoutes: Array<RouteRecordRaw> = [
	{
    path: '/orders/:orderId(\\d+)/payment/success',
    name: OrdersRoutesEnum.ORDERS_PAYMENT_SUCCESS,
    props: true,
    component: OrderPaymentSuccessPage,
    meta: {
      tabTitle: 'Ordine pagato'
    },
		beforeEnter: existingPaymentInProgress,

  },
	{
    path: '/orders/:orderId(\\d+)/payment/cancel',
    name: OrdersRoutesEnum.ORDERS_PAYMENT_CANCEL,
    props: true,
    component: OrderPaymentCancelPage,
    meta: {
      tabTitle: 'Pagamento annullato'
    },
		beforeEnter: existingPaymentInProgress
  },
]

export { routes, standAloneRoutes };
export { OrdersRoutesEnum };
