import { SessionStorageEnum } from '@/utils/SessionStorageEnum';
import { RouteRecordRaw } from 'vue-router';
import { PickersRoutesEnum } from './PickersRoutesEnum';

const PickersPage  = () => import(/* webpackChunkName: "Pickers-module" */ '../pages/pickers/PickersPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/pickers',
        name: PickersRoutesEnum.PICKERS_LIST,
        component: PickersPage,
        meta	 : {
				tabTitle: 'Rubrica',
				breadCrumbItems: [
					{label: 'Elenco punti di ritiro & consegna'},
				]
		},
		beforeEnter(_, __, next){
			sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_PICKERS_DETAIL);
			next();
		}
    },
];

export { routes };
export { PickersRoutesEnum };