import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "icon_inside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!

  return (_openBlock(), _createBlock(_component_FloatLabel, {
    class: "p-field",
    label: _ctx.label
  }, {
    default: _withCtx(() => [
      _createVNode(_component_InputText, _mergeProps({
        type: _ctx.showPwd ? 'text' : 'password'
      }, _ctx.$attrs), null, 16, ["type"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("i", {
          class: _normalizeClass(["pi", _ctx.showPwd ? 'pi-eye-slash' : 'pi-eye']),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleType && _ctx.toggleType(...args)), ["exact"]))
        }, null, 2)
      ])
    ]),
    _: 1
  }, 8, ["label"]))
}