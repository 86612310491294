import { DateRange } from 'moment-range';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { TakersRoutesEnum } from './TakersRoutesEnum';

const TakersPage    		= () => import(
	/* webpackChunkName: "Takers-module" */
	'../pages/takers/TakersPage.vue'
);

const TakerPage    			= () => import(
	/* webpackChunkName: "Takers-module" */
	'../pages/taker/TakerPage.vue'
);

const TakerData    			= () => import(
	/* webpackChunkName: "Takers-module" */
	'../pages/taker/TakerData/TakerData.vue'
);

const TakerZone    			= () => import(
	/* webpackChunkName: "Takers-module" */
	'../pages/taker/TakerZone/TakerZone.vue'
);

const TakerAvailabilities   = () => import(
	/* webpackChunkName: "Takers-module" */
	'../pages/taker/TakerAvailabilities/TakerAvailabilities.vue'
);

const AvailabilityPage    	= () => import(
	/* webpackChunkName: "av-module" */
	'../pages/availability/AvailabilityPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
    
	{
		path     : '/takers',
		name     : TakersRoutesEnum.TAKERS_LIST,
		component: TakersPage,
		meta	 : {
			tabTitle: 'Takers',
			breadCrumbItems: [
				{ label: 'Elenco takers' },
			]
		}
	},

	{
		path     : '/takers/:takerId',
		props    : true,
		component: TakerPage,
		
		children : [
			{
				path: '',
				name     : TakersRoutesEnum.TAKERS_DETAIL,
				component: TakerData 
			},
			{
				path: 'zone',
				name: TakersRoutesEnum.TAKERS_ZONE,
				component: TakerZone
			},
			{
				path: 'availabilities',
				name: TakersRoutesEnum.TAKERS_AVAILABILITIES,
				component: TakerAvailabilities
			},
		],

		meta	 : {
			tabTitle: 'Dettaglio Taker',
			breadCrumbItems: [
				{ label: 'Elenco takers', to: {name: TakersRoutesEnum.TAKERS_LIST} },
				{ label: 'Dettaglio taker' },
			]
		}
	},

	{
		path     : '/takers/availability',
		name     : TakersRoutesEnum.AVAILABILITY,
		props    : (to: RouteLocationNormalized) => {
			const { dates, zoneId } = to.query;

			return {
				dates: new DateRange(dates as string),
				zoneId: +zoneId
			}
		},
		component: AvailabilityPage,
		meta	 : {
			tabTitle: 'Disponibilità   Taker',
			breadCrumbItems: [
				{ label: 'Disponibilita takers' },
			]
		}
	},
];

export { routes };
export { TakersRoutesEnum };