import { RouteRecordRaw } from 'vue-router';
import { UsersRoutesEnum } from './UsersRoutesEnum';

const UsersPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/users/UsersPage.vue');
const UserPage 	= () => import(/* webpackChunkName: "Users-module" */ '../pages/user/UserPage.vue');


const routes: Array<RouteRecordRaw> = [
	
	{
		path     : '/users',
		name     : UsersRoutesEnum.USERS_LIST,
		component: UsersPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Elenco utenti'},
			]
		}
	},
	{
		path     : '/users/:userId',
		name     : UsersRoutesEnum.USER_DETAIL,
		props    : true,
		component: UserPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Elenco utenti', to: { name: UsersRoutesEnum.USERS_LIST }},
				{label: 'Dettaglio utente'},
			]
		}
	},

];

export { routes };
export { UsersRoutesEnum };