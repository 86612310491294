import { Options, Vue } from "vue-class-component";
import { Model }        from "vue-property-decorator";
import moment           from "moment";

/**
 * @deprecated
 * Con l'aggiornamento di PrimeVue, questo componente non sembra più necessario.
 * La selezione di un rage di Date è facilmente raggiungibile tramite 
 * il componente Calendar impostando l'apposita modalità di selezione "range".
 */
@Options({
  name: "DateRange",
})
export default class DateRange extends Vue {

  @Model('modelValue')
  fromTo: Date[];

  get calendar() {
    return (this.$refs as any).op;
  }

  get fromDate() {
    const from = this.fromTo?.[0];

    return from
      ? moment(from).format('DD/MM/YYYY')
      : "--/--/----";
  }

  get toDate() {
    const to = this.fromTo?.[1];

    return to
      ? moment(to).format('DD/MM/YYYY')
      : "--/--/----";
  }

  openCalendar(event) {
    this.calendar?.toggle(event);
  }

  hideCalendar() {
    this.calendar?.hide();
  }

  onApply() {
    this.updateAndCLose();
  }

  onReset() {
    this.fromTo = null; 
    this.updateAndCLose();
  }

  private updateAndCLose() {
    this.$emit("update", this.fromTo);
    this.hideCalendar();
  }
}