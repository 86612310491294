import { UserRoleEnum } from '@/model/User';
import { SessionStorageEnum } from '@/utils/SessionStorageEnum';
import { RouteRecordRaw } from 'vue-router';
import { CustomersRoutesEnum } from './CustomersRoutesEnum';

const CustomersPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/customers/CustomersPage.vue');

const CustomerPage   = () => import(/* webpackChunkName: "Users-module" */ '../pages/customer/CustomerPage.vue');
const CustomerData   = () => import(/* webpackChunkName: "Users-module" */ '../pages/customer/CustomerData/CustomerData.vue');
const CustomerUsers  = () => import(/* webpackChunkName: "Users-module" */ '../pages/customer/CustomerUsers/CustomerUsers.vue');
const CustomerZones  = () => import(/* webpackChunkName: "Users-module" */ '../pages/customer/CustomerZones/CustomerZones.vue');
const CustomerRubric = () => import(/* webpackChunkName: "Users-module" */ '../pages/customer/CustomerRubric/CustomerRubric.vue');

const routes:  Array<RouteRecordRaw> = [
	{
		path     : '/customers',
		name     : CustomersRoutesEnum.CUSTOMERS_LIST,
		component: CustomersPage,
		meta	 : {
			tabTitle: 'Clienti',
			breadCrumbItems: [
				{label: 'Elenco clienti'},
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		},
		beforeEnter(_, __, next){
			sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_CUSTOMER_DETAIL);
			next();
		}
	},
	{
		path     : '/customers/:customerId',
		props    : true,
		component: CustomerPage,
		children : [
			{
				path: '',
				name: CustomersRoutesEnum.CUSTOMERS_DETAIL,
				component: CustomerData 
			},
			{
				path: 'users',
				name: CustomersRoutesEnum.CUSTOMERS_USERS_LIST,
				component: CustomerUsers
			},
			{
				path: 'zones',
				name: CustomersRoutesEnum.CUSTOMERS_ZONES,
				component: CustomerZones
			},
			{
				path: 'rubric',
				name: CustomersRoutesEnum.CUSTOMERS_RUBRIC,
				component: CustomerRubric
			},
		],
		meta	 : {
			tabTitle: 'Dettaglio cliente',
			breadCrumbItems: [
				{label: 'Elenco clienti', to: { name: CustomersRoutesEnum.CUSTOMERS_LIST }},
				{label: 'Dettaglio cliente'},
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
];

export { routes };
export { CustomersRoutesEnum };