import { UserRoleEnum } from '@/model/User';
import { SessionStorageEnum }  from '@/utils/SessionStorageEnum';
import { RouteRecordRaw }      from 'vue-router';
import { SuppliersRoutesEnum } from './SuppliersRoutesEnum';

const SuppliersPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/suppliers/SuppliersPage.vue');

const SupplierPage 	= () => import(/* webpackChunkName: "Users-module" */ '../pages/supplier/SupplierPage.vue');
const SupplierData 	= () => import(/* webpackChunkName: "Users-module" */ '../pages/supplier/SupplierData/SupplierData.vue');
const SupplierUsers = () => import(/* webpackChunkName: "Users-module" */ '../pages/supplier/SupplierUsers/SupplierUsers.vue');
const SupplierUser  = () => import(/* webpackChunkName: "Users-module" */ '../pages/supplier/SupplierUser/SupplierUser.vue');
const SupplierZones	= () => import(/* webpackChunkName: "Users-module" */ '../pages/supplier/SupplierZones/SupplierZones.vue');

const routes:  Array<RouteRecordRaw> = [
	{
		path     : '/suppliers',
		name     : SuppliersRoutesEnum.SUPPLIERS_LIST,
		component: SuppliersPage,
		meta	 : {
			tabTitle: 'Elenco fornitori',
			breadCrumbItems: [
				{label: 'Elenco fornitori'},
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		},
		beforeEnter(_, __, next){
			sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_SUPPLIER_DETAIL);
			next();
		}
	},
	{
		path     : '/suppliers/:supplierId',
		props    : true,
		component: SupplierPage,
		children : [
			{
				path: '',
				name: SuppliersRoutesEnum.SUPPLIERS_DETAIL,
				component: SupplierData
			},
			{
				path: 'users',
				name: SuppliersRoutesEnum.SUPPLIERS_USERS_LIST,
				component: SupplierUsers
			},
			{
				path: 'users/:userId',
				name: SuppliersRoutesEnum.SUPPLIERS_USER_DETAIL,
				props: true,
				component: SupplierUser
			},
			{
				path: 'zones',
				name: SuppliersRoutesEnum.SUPPLIERS_ZONES,
				component: SupplierZones
			},
		],
		meta	 : {
			tabTitle: 'Dettaglio fornitore',
			breadCrumbItems: [
				{label: 'Elenco fornitori', to: {name: SuppliersRoutesEnum.SUPPLIERS_LIST} },
				{label: 'Dettaglio fornitore' },
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
];

export { routes };
export { SuppliersRoutesEnum };
