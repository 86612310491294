export interface IBaseApiModel {
    id?: number;

    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

export abstract class BaseApiModel implements IBaseApiModel {
    id?: number;

    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;
}