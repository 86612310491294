export enum SessionStorageEnum {
    TABLE_STATE_USERS           = "DT_USERS_STATE",

    TABLE_STATE_SUPPLIERS       = "DT_SUPPLIERS_STATE",
    TABLE_STATE_SUPPLIER_DETAIL = "DT_SUPPLIER_DETAIL_STATE",
    
    TABLE_STATE_CUSTOMERS       = "DT_CUSTOMERS_STATE",
    TABLE_STATE_CUSTOMER_DETAIL = "DT_CUSTOMER_DETAIL_STATE",
    
    TABLE_STATE_TAKERS          = "DT_TAKERS_STATE",
    TABLE_STATE_ZONES           = "DT_ZONES_STATE",
    TABLE_STATE_ORDERS          = "DT_ORDERS_STATE",
    ORDERS_ADVANCED_FILTERS     = "DT_ORDERS_ADV_FILTERS",

    TABLE_STATE_PICKERS_DETAIL  = "DT_PICKERS_STATE",
    TABLE_STATE_BADGES           = "DT_BADGES_STATE",
}