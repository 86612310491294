import { NewOrder } from "@/model/Order"

/**
* AD ogni cambiamento di stato, 
* aggiorna il valore dell'ordine in LocalStorage
* @param store 
*/
export const NewOrderPlugin = (store) => {

 function updateStorageOrder(data) {

  const update: NewOrder = {
    ...(JSON.parse(localStorage.getItem('new_order')) || {}),
    ...data
  }

  update.note_customer  && delete update.note_customer
  update.note_supplier  && delete update.note_supplier
  update.note_tmt       && delete update.note_tmt

  localStorage.setItem('new_order', JSON.stringify(update))
 }

 store.subscribe((mutation, state) => {
   if (mutation.type.startsWith("orders/")) {
         updateStorageOrder(state.orders.newOrder)
     }
 })
}
