import { BaseApiModel } from "@/model/common/BaseApiModel";
import { Entity } from "./Entity";
import { Zone } from "./Zone";

export enum UserRoleEnum {
    SUPER_ADMIN    =  1,
    TMT_OPERATOR   = 10,
    CUSTOMER_ADMIN = 20,
    CUSTOMER_USER  = 30,
    TAKER          = 40,
    SUPPLIER_ADMIN = 50,
    SUPPLIER_USER  = 60
}

export class User extends BaseApiModel {

    name?       : string;
    surname?    : string;
    email?      : string;
    role?       : UserRoleEnum;
    entity_id?  : number;
    entity?     : Entity;
    is_active?  : boolean = true;

    //Utili in edit
    password?           : string;
    confirmPassword?   : string;

    zones?: Zone[];
}