
import { UserRoleEnum } from '@/model/User';
import { AuthRoutesEnum } from '@/modules/auth/router';
import { OrdersRoutesEnum } from '@/modules/orders/router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { guards, tabTitle } from './guards';
import { MainRoutesEnum } from './MainRoutesEnum';

const AvalonTemplate =
  () => import(
    /* webpackChunkName: "avalon-template" */
    '@/templates/avalon-template/AvalonTemplate.vue'
  );
const ProfilePage =
  () => import(
    /* webpackChunkName: "avalon-template" */
    '@/modules/app/pages/Profile/ProfilePage.vue'
  );
const BusinessProfilePage =
  () => import(
    /* webpackChunkName: "avalon-template" */
    '@/modules/app/pages/BusinessProfile/BusinessProfilePage.vue'
  );

const TakerPlanningPage =
  () => import(
    /* webpackChunkName: "avalon-template" */
    '@/modules/app/pages/TakerPlanning/TakerPlanningPage.vue'
  );
const TimeSlotsPage =
  () => import(
    /* webpackChunkName: "avalon-template" */
    '@/modules/app/pages/TimeSlots/TimeSlotsPage.vue'
  );

const VehiclesPage =
  () => import(
    /* webpackChunkName: "avalon-template" */
    '@/modules/app/pages/Vehicles/VehiclesPage.vue'
  );

const OrderPaymentSuccessPage = () => import(
  /* webpackChunkName: "Orders-payment" */
  '@/modules/orders/pages/order-payment-success/OrderPaymentSuccessPage.vue'
);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/admin/orders'
  },

  {
    path: '/',
    component: AvalonTemplate,
    name: MainRoutesEnum.AVALON_TEMPLATE,
    children: [
      {
        path: '/kpi',
        name: MainRoutesEnum.KPI,
        component: () => import('@/modules/app/pages/DashboardPage.vue'),
        meta: {
          tabTitle: 'KPI',
          breadCrumbItems: [
            { label: 'KPI' },
          ],
          roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR, UserRoleEnum.CUSTOMER_ADMIN, UserRoleEnum.CUSTOMER_USER,
          UserRoleEnum.SUPPLIER_ADMIN, UserRoleEnum.SUPPLIER_USER]
        },
      },
      {
        path: '/profile',
        name: AuthRoutesEnum.CURRENT_USER,
        component: ProfilePage,
        props: true,
        meta: {
          tabTitle: 'Profilo',
          breadCrumbItems: [
            { label: 'Profilo' },
          ]
        }
      },
      {
        path: '/business-profile',
        name: AuthRoutesEnum.BUSINESS_PROFILE,
        component: BusinessProfilePage,
        props: true,
        meta: {
          tabTitle: 'Profilo aziendale',
          breadCrumbItems: [
            { label: 'Profilo aziendale' },
          ]
        }
      },
      {
        path: '/taker-planning',
        name: MainRoutesEnum.PLANNING,
        component: TakerPlanningPage,
        props: true,
        meta: {
          tabTitle: 'Pianificazione',
          breadCrumbItems: [
            { label: 'Pianificazione' },
          ]
        }
      },
      {
        path: '/time-slots',
        name: MainRoutesEnum.TIME_SLOTS,
        component: TimeSlotsPage,
        props: true,
        meta: {
          tabTitle: 'Fasce orarie',
          breadCrumbItems: [
            { label: 'Fasce orarie' },
          ]
        }
      },
      {
        path: '/vehicles',
        name: MainRoutesEnum.VEHICLES,
        component: VehiclesPage,
        props: true,
        meta: {
          tabTitle: 'Tipologie',
          breadCrumbItems: [
            { label: 'Lista Tipologie' },
          ],
          roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
        }
      },
    ],
    meta: {
      requiresAuth: true
    },
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/admin/orders'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

guards(router);
tabTitle(router);

export default router
