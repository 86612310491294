
import { defineComponent, ref } from 'vue'

export default defineComponent({
  
  inheritAttrs: false,

  props: ['label'],
  
  setup() {
    const showPwd = ref<boolean>(false)

    function toggleType() {
      showPwd.value = !showPwd.value
    }

    return {
      showPwd,
      toggleType
    }
  },
})
