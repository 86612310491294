import { NewOrder } from "@/model/Order";

export interface OrdersState {
    newOrder: NewOrder
}

const state: OrdersState = {
    newOrder: new NewOrder()
};

export default state;