import { SortMeta, SortOrderEnum } from "../common/PrimeVueTypes";

export class BaseApiFilter {
    /**
     * #### Numero dei record da visualizzare
     * *NB: Passare -1 per ricevere tutti i record della tabella*
     */
    per_page?: number = 10; 

    /**
     * #### Numero della pagina desiderata 
     * *NB: Non necessario se "per_page" è impostato a -1*
     */
    page?: number = 0; 

    //------ SORT SINGOLO --------- //
    /**
     * #### Chiave su cui effettuare l'ordinamento
     */
    sortField?: string = null;

    /**
     * #### Verso ordinamento  
     * 1 ASC  
     * -1 DESC 
     */
    sortOrder?: SortOrderEnum = SortOrderEnum.ASC;

    //------ SORT MULTIPLO ---------- //

    /**
     * #### Raccolta di chiavi e verso dell'ordinamento
     */
    multisSortMeta?: SortMeta[];
    
    /**
     * #### Filtri dinamici
     */
    filters?: any;

    /**
     * Query testuale 
     */
    q?: string = null; 

    constructor(data?: BaseApiFilter) {
        if (data) {
            Object.assign(this, data);
        }
    }

    /**
     * Ritorna un oggetto BaseApiFilter partendo da una stringa di ricerca
     * Con eventualmente altri campi di filtro
     * @param value stringa di ricerca
     * @param other oggetto con altri filtri
     * @returns BaseApiFilter
     */
    static fromGlobal(value: string, other?: Record<string, any>){
        let x = new BaseApiFilter();

        x = {
            ...x,
            per_page: 50, 
            filters: {
                global: {
                    value, matchMode: 'STARTS_WITH' 
                }
            },
            ...(other || {})
        };

        return x; 
    }
}