<template>
  <img
    alt="Logo"
    class="topbar-logo"
    :src="`/images/${logo}`"
/>
</template>

<script>
export default {
  computed: {
    logo() {
      return this.$config.appLogo;
    },
  },
}
</script>