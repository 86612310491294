import { FilterMatchMode } from "primevue/api";

export interface PrimeVueEvent extends Event {
    originalEvent: any; 

    filterMatchModes: any;
    filters: any;

    /**
     * Indice del primo record in visualizzazione 
     */
    first: number;

    /**
     * Pagina richiesta
     */
    page: number;

    /**
     * Numero delle pagine attualmente conteggiate
     */
    pageCount: number;

    /**
     * Numero delle righe in visualizzazione 
     */
    rows: number;

    /**
     * In caso di sort multiplo
     */
    multiSortMeta: SortMeta[];
    /**
     * In caso di sort singolo  
     * indica il nome del campo su cui effettuare il sort 
     */
    sortField: string;

    /**
     * In caso di sort singolo
     * indica il verso dell'ordinamento 
     * @see {SortOrderEnum}
     */
    sortOrder: SortOrderEnum;
}

export enum SortOrderEnum {
    ASC     = 1,
    DESC    = -1
}

export interface SortMeta {
    field: string;
    order: SortOrderEnum; 
}

export interface PrimeVueFilters  {
    [key:string] : {
        value: any,
        matchMode: typeof FilterMatchMode
    }
}