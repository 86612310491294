import { App }       from 'vue';

import { 
    BackButton,
    DateFormatted,
    FloatLabel,
    InfoIcon,
    TableSkeleton
} from '@sharedComponents';

import AppLogo from './AppLogo/AppLogo.vue';
import DeleteButton  from './DeleteButton/DeleteButton.vue';
import TimeStepper   from './TimeStepper/TimeStepper.vue';
import InputPassword from './InputPassword/InputPassword.vue';


export function registerGlobalComponents(app: App){
    app.component('AppLogo',        AppLogo);
    app.component('BackButton',     BackButton);
    app.component('DeleteButton',   DeleteButton);
    app.component('DateFormatted',  DateFormatted);
    app.component('FloatLabel',     FloatLabel);
    app.component('InfoIcon',       InfoIcon);
    app.component('InputPassword',  InputPassword);
    app.component('TimeStepper',    TimeStepper);
    app.component('TableSkeleton',  TableSkeleton);
}