import { Module } from 'vuex';

import { CreateActionsHandler, CreateGettersHandler, CreateMutationsHandler } from '@/store/common';
import { ModulesEnum }  from '@/store/modules';
import { AppState }    from '@/store/types';

import actions, { Actions }     from './actions';
import getters, { Getters }     from './getters';
import mutations, { Mutations } from './mutations';
import state, { OrdersState }   from './state';

export const OrdersStoreModule: Module<OrdersState, AppState> = {
    namespaced: true,

    state,
    mutations   : { ...mutations },
    actions     : { ...actions  },
    getters     : { ...getters  },
};

export const ordersStoreModule = {
    actions:    CreateActionsHandler<Actions, OrdersState>(ModulesEnum.ORDERS,      OrdersStoreModule),
    mutations:  CreateMutationsHandler<Mutations, OrdersState>(ModulesEnum.ORDERS,  OrdersStoreModule),
    getters:    CreateGettersHandler<Getters, OrdersState>(ModulesEnum.ORDERS,      OrdersStoreModule)
}