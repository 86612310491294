import "@sharedUtils/strings";
import "@sharedUtils/collections";

import { Loader } from "@googlemaps/js-api-loader";

import { createApp, Plugin } from 'vue';

// BOOTSTRAP COMPONENT
import MainRouter from './MainRouter.vue';

// STYLES
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@/assets/primevue/theme/theme-tmt.scss';
import '@/assets/primevue/layout/css/layout-sunkist.scss';

// CUSTOM SCSS
import './app.scss';

// CUSTOM PLUGINS
import { ConfigPlugin, DialogPlugin, LocalstoragePlugin, MessagesPlugin, UtilityPlugin } from '@/plugins';

// VUE I18N https://vue-i18n.intlify.dev/
import i18n from './i18n';

// MOMENT https://momentjs.com/docs/#
import moment from "moment";
// VUE ROUTER https://next.router.vuejs.org/
import router from './router';

// VUE STORE https://next.vuex.vuejs.org/
import store, { key_store } from './store';

import { initPrimeVue }    from '@/primevue';
import { initFontawesome } from '@/fontawesome';

// VEE-VALIDATE
import '@/vee-validate';

// UTILS
import { logAppVersion } from './utils/logAppVersion';

import { ModulesEnum } from './store/modules';

// MODULES
import { MainRoutesEnum }  from './router/MainRoutesEnum';
import { ModuleOptions }   from '@/modules/common/ModuleOptions';
import { AuthModule }      from '@/modules/auth';
import { AuthRoutesEnum }  from "@/modules/auth/router";
import { UsersModule }     from '@/modules/users';
import { ZonesModule }     from '@/modules/zones';
import { CustomersModule } from '@/modules/customers';
import { SuppliersModule } from '@/modules/suppliers';
import { TakersModule }    from '@/modules/takers';
import { PickersModule }   from "@/modules/pickers";
import { OrdersModule }    from "@/modules/orders";
import { PlanningModule }  from "@/modules/planning";
import { BillingModule }   from "@/modules/billing";
import { BadgeModule }   from "@/modules/badges";

// SERVICES
import { authService }     from "@services/auth.service";
import { configuration }   from "@plugins/Config-plugin";
import { localStorageSVC } from "@plugins/LocalStorage-plugin";

// GLOBAL COMPONENTS
import { registerGlobalComponents } from '@components/globalComponents';
import { MessagesModule }           from "@/modules/messages";
import { createPusher }             from "@/pusher";

moment.locale( 'it' );

logAppVersion();

const app = createApp( MainRouter );

const modules: { module: Plugin, options: ModuleOptions }[] = [
    { module: AuthModule, options: { storeName: ModulesEnum.AUTH, } },
    { module: UsersModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE, storeName: ModulesEnum.USERS } },
    { module: CustomersModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: SuppliersModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: TakersModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: ZonesModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: OrdersModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE, storeName: ModulesEnum.ORDERS } },
    { module: PickersModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: PlanningModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: BillingModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: MessagesModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
    { module: BadgeModule, options: { parentRoute: MainRoutesEnum.AVALON_TEMPLATE } },
];

modules.forEach(({ module, options }) => {
    options.router = router;
    options.store  = store;
    app.use( module, options );
});

app.use(i18n);
app.use(store, key_store);

app.use(DialogPlugin);
app.use(ConfigPlugin);
app.use(LocalstoragePlugin);
app.use(MessagesPlugin);
app.use(UtilityPlugin);

initPrimeVue(app);
initFontawesome(app);

registerGlobalComponents(app);

export function handle401(error) {
    if (error?.status === 401) {
        localStorageSVC.clearToken();
        router.replace( { name: AuthRoutesEnum.SIGN_IN } );
    }
}

app.config.errorHandler = (error: any, vm, info) => {
    console.error(error);
    console.info(info);

    handle401(error);

    vm.$errorMessage(null, "Errore non gestito");
};

function loadGoogle(){
    if (!window.google) {
        const mapLoader = new Loader({
            apiKey: configuration.mapApiKey,
            version: configuration.mapVersion,
            libraries: ["places"],
            language: "it",
        });

        return mapLoader.load();
    }
}

async function initUser() {
    if (localStorageSVC.token) {
        try {
            const response = await authService.me();
            return store.commit( `${ ModulesEnum.AUTH }/setMe`, response );
        } catch (error) {
            localStorageSVC.clearToken();
        }
    }
}

async function initPusher() {
    if (localStorageSVC.token) {
        return createPusher( localStorageSVC.token );
    }
}

const init = Promise.all( [
    initPusher(),
    loadGoogle(),
    initUser()
] );

init.then( () => {
    app.use( router );
    app.mount( '#app' )
});

