import { ActionTree }   from 'vuex';
import { OrdersState }    from './state';
import { AppState }     from '@/store/types';

export interface Actions {
    actionName(data: any): Promise<void>; 
}


const actions: ActionTree<OrdersState, AppState> = {
    
}

export default actions;