import { Plugin }             from 'vue';
import { localStorageSVC }    from './LocalStorage-plugin';
import { AuthRoutesEnum }     from '@/modules/auth/router';
import { authStore }          from '@/modules/auth/store';
import { User, UserRoleEnum } from '@/model/User';
import { isTouchDevice }      from '@sharedUtils/utils';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {

        /**
         * Indica se il dispositivo in uso è un touch o meno
         */
        isTouch: boolean; 

        /**
         * Indica se è in corso una request bloccante
         */
        requestPending: boolean; 

        me: User;

        /**
         * Il ruolo dell'utente corrente
         */
        currentRole: UserRoleEnum;

        isTMT      : boolean;
        isCustomer : boolean;
        isSupplier : boolean;
        
        /**
         * Gestisce una chiamata asincrona "importante"  
         * 
         * Setta requestPending a true prima della chiamata
         * e lo resetta al termine, a prescindere dal risultato.
         * 
         * E' previsto un messaggio d'errore se necessario
         */
        readonly $waitFor: (
            request: () => Promise<any>, 
            errorMessage?: string
        ) => Promise<any>;
    }
}

const UtilityPlugin: Plugin = {
    install(app) {
        console.debug("Installing Utility plugin...");

        app.mixin({
            data(){
                return {
                    requestPending: false
                }
            },
            computed: {
                isTouch() {
                    return isTouchDevice();
                },

                me() {
                    return authStore.getters.me;
                },

                currentRole(){
                    return authStore.getters.userRole;
                },

                isTMT(){
                    return [
                      UserRoleEnum.SUPER_ADMIN,
                      UserRoleEnum.TMT_OPERATOR,
                    ].includes(this.currentRole);
                },

                isCustomer(){
                  return [
                    UserRoleEnum.CUSTOMER_ADMIN,
                    UserRoleEnum.CUSTOMER_USER,
                  ].includes(this.currentRole);
                },
              
                isSupplier(){
                  return [
                    UserRoleEnum.SUPPLIER_ADMIN,
                    UserRoleEnum.SUPPLIER_USER,
                  ].includes(this.currentRole);
                }
            }
        })

        app.config.globalProperties.$waitFor =
            async function (request: () => Promise<any>, errorMessage: string = "Qualcosa è andato storto") {
                try {
                    this.requestPending = true;

                    return await request();

                } catch (error: any) {
                    console.error(error);
                    
                    if ( error?.status === 401 ){
                        localStorageSVC.clearToken();
                        this.$router.replace({ name: AuthRoutesEnum.SIGN_IN });
                    }

                    if ( error?.status === 404 && this.$route?.name?.includes('detail') ){
                        await this.$router.replace({
                            name: this.$route?.name.replace('detail', 'list')
                        });
                    }

                    if ( error?.status === 422 ) {
                        const fieldsInError = Object.keys(error.data.errors)
                    
                        if (fieldsInError?.length) {
                            fieldsInError.forEach(k => {
                                this.$errorMessage(
                                    flattenString(error.data.errors[k])
                                )
                            })
                        }

                    } else {
                        this.$errorMessage(errorMessage);
                    }
                } finally {
                    this.requestPending = false;
                }
            };
    }
};

function flattenString(a: string[]) {
    return a?.join(" ")
}

export default UtilityPlugin;