import TableMixin from "../TableMixin";

import { mixins, Options }  from "vue-class-component";

@Options({
    name: "DynamicTable",
    props: ['cols'],
})
export default class DynamicTable extends mixins(TableMixin) {

    getComponent(component){
        if (typeof component === "string"){
            return `vue:${component}`;
        } else {
            return component; 
        }
    }
}