import { cloneElement } from "preact";
import { BaseApiModel }                                from "./common/BaseApiModel";
import { Customer }                                    from "./Customer";
import { Entity, EntityPicker, EntityPickerType, PaymentType, UserType } from "./Entity";
import { Shift }                                       from "./Shift";
import { Supplier }                                    from "./Supplier";
import { User }                                        from "./User";
import { Vehicle }                                     from "./Vehicle";
import { Zone }                                        from "./Zone";

export interface OrderZone {
    id: number;
    name: string; 
}
export interface Order extends BaseApiModel {
    ref_sender_name: string; 
    ref_sender_phone: string; 
    
    external_client_code?: string; 

    customer_id ?: number;

    entity_type ?: UserType;
    entity_id   ?: number;

    user_id ?: number;
    payment_type ?: PaymentType;

    /**
     * N. Colli
     */
    items_qty    ?: number;

    // Fascia oraria
    shift_id     ?: number;
    shift  ? : Shift;
    
    // Zone
    zone_id      ?: number;
    zone_name    ?: string; 
    zone: OrderZone;
    
    // Veicolo
    vehicle_id ? : number;
    vehicle    ? : Vehicle;

    // Info. Punto di prelievo
    pickup_picker_id    ?: number;
    pickup_picker_name  ?: string;
    pickup_picker       ?: EntityPicker;
    pickup_full_address ?: string;
    pickup_cap          ?: string;
    pickup_lat          ?: number;
    pickup_lon          ?: number;
    pickup_zone_shape   ?: number;
    pickup_note         ?: string;
    pickup_image_path   ?: string;
    pickup_image_url    ?: string;

    /**
     * Data di prelievo 
     */
    pickup_datetime  ?: Date;
    pickup_real_datetime  ?: Date;
    
    /**
     * Se true = Prelievo urgente
     */
    pickup_priority?: boolean;

    // Info. Punto di consegna 
    dropoff_picker_id    ?: number;
    dropoff_picker_name  ?: string;
    dropoff_picker       ?: EntityPicker;
    dropoff_full_address ?: string;
    dropoff_cap          ?: string;
    dropoff_lat          ?: number;
    dropoff_lon          ?: number;
    dropoff_zone_shape   ?: number;
    dropoff_note         ?: string;
    dropoff_image_path   ?: string;
    dropoff_image_url    ?: string;

    /**
     * Firma alla consegna
     */
    dropoff_signature?: string;

    /**
     * Data di consegna
     */
    dropoff_datetime ?: Date;
    dropoff_real_datetime  ?: Date;

    /**
     * Se true = Deposito urgente
     */
    dropoff_priority?: boolean;

    km_road                ?: number;
    eta                    ?: number;
    supplier_track_code    ?: any;
    weekday                ?: number;
    status                 ?: number;
    status_last_datetime   ?: string;
    est_price              ?: number;
    est_price_extra1_desc  ?: any;
    est_price_extra1_value ?: number;
    est_cost               ?: number;
    est_cost_extra1_desc   ?: any;
    est_cost_extra1_value  ?: number;
    cost_extra_shape1      ?: number;
    cost_extra_vehicle     ?: number;
    final_price            ?: number;
    final_cost             ?: number;
    time_waiting_pickup    ?: any;
    time_waiting_dropoff   ?: any;
    time_travel            ?: any;

    
    tot_est_price          ?: number;
    tot_est_cost           ?: number;
    
    value_logs ?: any[];


    user       ?: User;

    customer? : Customer;
    supplier? : Supplier;
    entity?   : Entity;

    detail ? : any;

    // Note
    note_tmt               ?: string;
    note_customer          ?: string;
    note_supplier          ?: string;

    calc_log_price?: string;
    calc_log_cost?: string;


    // Payment fields 
    payment_status?: PaymentStatusEnum;
    payment_amount?: number;
}

export enum PaymentStatusEnum {
    TO_PAY = 10, 
    PAID = 20,
    REFUNDED = 30,
}

export class NewOrder {
    zone: Zone;
    customer: Customer;

    ref_sender_name: string; 
    ref_sender_phone: string;
    external_client_code: string;

    shift: Shift; 
    shift_id: number; 

    supplier: Supplier; 

    entity_id: number;
    entity_type: UserType;
    
    //#region PICKUP
    pickup_lat: number;
    pickup_lon: number;
    pickup_cap: string;

    pickup_datetime  : Date = new Date();
    pickup_priority?: boolean = false;

    is_save_new_pickup : boolean = true;
    pickup_picker_name : string;
    pickup_picker_id   : number;
    pickup_picker      : EntityPicker;
    pickup_picker_served: boolean = true;
    //#endregion

    //#region DROPOFF
    dropoff_lat: number;
    dropoff_lon: number;
    dropoff_cap: string;

    dropoff_datetime : Date;
    dropoff_priority?: boolean = false;

    is_save_new_dropoff : boolean = false;
    dropoff_picker_name : string;
    dropoff_picker_id   : number;
    dropoff_picker      : EntityPicker;
    dropoff_picker_served: boolean = true;
    //#endregion

    items_qty        : number = 1;

    vehicle         : Vehicle;
    vehicle_code    : string;
    
    note_tmt               : string;
    note_customer          : string;
    note_supplier          : string;
    
    
    resetPickup() {
        this.pickup_lat = null;
        this.pickup_lon = null;
        this.pickup_cap = null;

        this.is_save_new_pickup = null;
        this.pickup_picker_name = null;
        this.pickup_picker_id   = null;
        this.pickup_picker      = null;
        return this;
    }
    
    resetDropoff() {
        this.dropoff_lat = null;
        this.dropoff_lon = null;
        this.dropoff_cap = null;

        this.is_save_new_dropoff    = null;
        this.dropoff_picker_name    = null;
        this.dropoff_picker_id      = null;
        this.dropoff_picker         = null;
        return this;
    }

    resetCustomer() {
        this.customer = null; 
        return this;
    }

    resetOtherDataSection() {
        this.ref_sender_name      = null;
        this.ref_sender_phone     = null;
        this.external_client_code = null;
        return this;
    }

    static fromExistingOrder(order: Order, zone?: Zone) {
        const newOrder = new NewOrder();

        if (zone) {
            newOrder.zone = zone ;
        } else {
            newOrder.zone      = new Zone();
            newOrder.zone.id   = order.zone.id;
            newOrder.zone.name = order.zone.name;
        }

        newOrder.customer = order.customer;

        if (order.pickup_picker) {
            newOrder.pickup_picker = order.pickup_picker;
        } else {
            newOrder.pickup_picker = new EntityPicker({
                type    : EntityPickerType.PICKUP,
                id      : order.pickup_picker_id,
                name    : order.pickup_picker_name,
                lat     : order.pickup_lat,
                lon     : order.pickup_lon,
                address : order.pickup_full_address,
                note    : order.pickup_note,
                postal_code: order.pickup_cap,
            });
        }

        newOrder.pickup_datetime = order.pickup_datetime;
        newOrder.pickup_priority = order.pickup_priority;

        if (order.dropoff_picker) {
            newOrder.dropoff_picker = order.dropoff_picker;
        } else {
            newOrder.dropoff_picker = new EntityPicker({
                type    : EntityPickerType.DROPOFF,
                id      : order.dropoff_picker_id,
                name    : order.dropoff_picker_name,
                lat     : order.dropoff_lat,
                lon     : order.dropoff_lon,
                address : order.dropoff_full_address,
                note    : order.dropoff_note,
                postal_code: order.dropoff_cap,
            });
        }
        
        newOrder.dropoff_datetime = order.dropoff_datetime;
        newOrder.dropoff_priority = order.dropoff_priority;

        newOrder.external_client_code = order.external_client_code;

        newOrder.ref_sender_name    = order.ref_sender_name;
        newOrder.ref_sender_phone   = order.ref_sender_phone;

        newOrder.shift_id   = order.shift_id;
        newOrder.shift      = order.shift;
        newOrder.vehicle    = order.vehicle;

        return newOrder;
    }

    toCreationRequest(){
        const clone = JSON.parse(JSON.stringify(this));

        const { pickup_picker: pp, dropoff_picker: dp } = clone;

        clone.pickup_picker_id    = pp.id;
        clone.is_save_new_pickup  = !pp.id;
        
        clone.pickup_picker_name  = pp.name;
        clone.pickup_full_address = pp.address;
        clone.pickup_cap          = pp.postal_code;
        clone.pickup_lat          = pp.lat;
        clone.pickup_lon          = pp.lon;
        clone.pickup_note         = pp.note;

        clone.dropoff_picker_id   = dp.id;
        clone.is_save_new_dropoff = !dp.id;
        
        clone.dropoff_picker_name  = dp.name;
        clone.dropoff_full_address = dp.address;
        clone.dropoff_cap          = dp.postal_code;
        clone.dropoff_lat          = dp.lat;
        clone.dropoff_lon          = dp.lon;
        clone.dropoff_note         = dp.note;
        
        Object.keys(clone).forEach(k => {
            if (clone[k] === null) {
                delete clone[k];
            }
        });

        const {
            customer, 
            vehicle,
            zone,
            supplier,
            shift,
            ...other
        } = clone; 


        return {
            ...other, 
            zone_id     : zone.id,
            customer_id : customer.id, 
            vehicle_id  : vehicle?.id,
            shift_id    : shift?.id,
            items_qty   : other.items_qty || 1,
        }

    }
}