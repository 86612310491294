export enum OrdersRoutesEnum{
    ORDERS_IMPORT       = 'orders_import',
    ORDERS_LIST         = 'orders-list',
    ORDERS_DETAIL       = 'orders-detail',
    ORDERS_NEW          = 'orders-new',
    ORDERS_NEW_ZONE     = 'orders-new-zone',
    ORDERS_NEW_CUSTOMER = 'orders-new-customer',
    ORDERS_NEW_PICKUP   = 'orders-new-pickup',
    ORDERS_NEW_DROPOFF  = 'orders-new-dropoff',
    ORDERS_NEW_DATA     = 'orders-new-data',
    ORDERS_NEW_COMPLETE = 'orders-new-complete',

    ORDERS_LIST_AS_CUSTOMER = 'orders-list-as_customer',
    ORDERS_LIST_AS_SUPPLIER = 'orders-list-as_supplier',

    ORDERS_PAYMENT_SUCCESS = "orders-payment-success",
    ORDERS_PAYMENT_CANCEL = "orders-payment-cancel"

}