{
  "login.password_label": "Password",
  "login.subtitle": "Enter your credentials to log in",
  "login.title": "Sign in",
  "login.username_label": "Username",
  "login.username_placeholder": "username",
  "login.password_placeholder": "password",
  "login.error_message": "Wrong credentials. \nPlease check and try again.",
  "users.list.title": "User list",
  "user.edit.title": "User detail",
  "role.1": "Super admin",
  "role.10": "TMT operator",
  "role.20": "Customer admin",
  "role.30": "Customer user",
  "role.40": "Taker",
  "role.50": "Supplier admin",
  "role.60": "Supplier user",
  "user.new.title": "Create new user",
  "user.password.title": "Password",
  "suppliers.new.title": "New supplier",
  "suppliers.detail.title": "Supplier detail",
  "takers.new.title": "New taker",
  "takers.detail.title": "Taker detail",
  "save": "Save",
  "delete": "Delete",
  "back": "Back",
  "login.forget_pwd": "Forgot password ?",
  "entity.name": "First name",
  "login.sign_up": "Sign in",
  "registration.succesfull": "Registration completed",
  "registration.welcome": "Welcome",
  "business_profile.title": "Company Profile",
  "takers_availability.list.title": "Takers availability"
}
