import { plainToClass } from "class-transformer";
import { BaseApiModel } from "./common/BaseApiModel";
import { ShiftCost } from "./ShiftCost";
import { VehicleData } from "./Vehicle";

export interface IZone {
    name?: string; 

    shape1?: Path;
    shape2?: Path;
    shape3?: Path;
    shape4?: Path;
    shape5?: Path;
    
    is_shape1_active?: boolean;
    is_shape2_active?: boolean;
    is_shape3_active?: boolean;
    is_shape4_active?: boolean;
    is_shape5_active?: boolean;
    
    price_shape1?:  number;
    price_shape2?:  number;
    price_shape3?:  number;
    price_shape4?:  number;
    price_shape5?:  number;

    cost_shape1?: number;
    cost_shape2?: number;
    cost_shape3?: number;
    cost_shape4?: number;
    cost_shape5?: number;

    price_km_flat?:       number;
    cost_km_flat?:        number;

    shift_costs?: ShiftCost[];
    vehicle_data ?: VehicleData[];
}

export class Zone extends BaseApiModel implements IZone{
    name?: string; 
    is_active: boolean; 

    shape1?: Path;
    shape2?: Path;
    shape3?: Path;
    shape4?: Path;
    shape5?: Path;
    
    is_shape1_active?: boolean;
    is_shape2_active?: boolean;
    is_shape3_active?: boolean;
    is_shape4_active?: boolean;
    is_shape5_active?: boolean;
    
    price_shape1?:  number;
    price_shape2?:  number;
    price_shape3?:  number;
    price_shape4?:  number;
    price_shape5?:  number;

    cost_shape1?: number;
    cost_shape2?: number;
    cost_shape3?: number;
    cost_shape4?: number;
    cost_shape5?: number;

    price_km_flat? : number;
    cost_km_flat?  : number;

    shift_costs?: ShiftCost[];

    vehicle_data ?: VehicleData[];

    get biggestShape() {
        return [
            this.shape1,
            this.shape2,
            this.shape3,
            this.shape4,
            this.shape5,
        ].filter( s => s.length > 0).last();
    }

    get bounds() {
        if (!this.biggestShape?.length) return;

        return this.biggestShape.reduce(
            (x, y) => {
                const lon = y[0];
                const lat = y[1];

                x.minLat = (!x.minLat || x.minLat > lat) ? lat : x.minLat;
                x.minLon = (!x.minLon || x.minLon > lon) ? lon : x.minLon;

                x.maxLat = (!x.maxLat || x.maxLat < lat) ? lat : x.maxLat;
                x.maxLon = (!x.maxLon || x.maxLon < lon) ? lon : x.maxLon;

                return x;
            },
            { minLat: null, maxLat: null, minLon: null, maxLon: null} 
        )
    }

    /**
     * Raccoglie le informazioni di una singola area in un solo oggetto
     * @param shapeNumber 
     * @returns 
     */
    getShapeInfo(shapeNumber: number): ShapeInfo{
        return {
            idx  : shapeNumber,
            name : `Area #${shapeNumber}`,

            coordinates : this[`shape${shapeNumber}`],
            price       : this[`price_shape${shapeNumber}`],
            cost        : this[`cost_shape${shapeNumber}`],
            isActive    : this[`is_shape${shapeNumber}_active`] || false,
            isVisible   : true,
            zIndex      : 60 - (shapeNumber * 10),
            polygonRef  : null
        }
    }

    /**
     * Trasforma i dati provenienti dalle API in un array;  
     * 
     * --- 
     * 
     * Inizialmente vengono raggruppati per fascia oraria (shif_id).  
     * Per ciascuna fascia oraria potranno essere presenti più valori,
     * da 1 a 7, uno per ciascun giorno della settimana.  
     * Questi valori vengono rielaborati affinché ciascun giorno sia 
     * rappresentato dall'oggetto contenuto nella chiave "weekDay#".
     * (# -> sta per il numero del giorno della settimana).  
     * Inoltre per completezza di informazioni, in ciascun oggetto risultante
     * è presente il dettaglio della fascia oraria di riferimento. 
     * 
     * ---
     * @returns any[]
     */
    getGroupedShiftCosts(){
        const groupedByShift = this.shift_costs
        ?.map(s => {
            const { id, ...other} = s;

            return {
                ...other,
                shift_cost_id: id
            }
        })
        ?.groupBy('shift_id');

        const result = [];
        groupedByShift?.forEach( (a,k) => {
            const row = a?.reduce( (acc, item) => {
                acc = {
                    ...acc,
                    shift: item.shift,
                    [`weekDay${item.weekday}`] : item
                }

                return acc;
            }, {})

            result.push(row);
        });

        return result;
    }
    
}

type Lat = number;
type Lng = number;

type Point = [Lng, Lat];

export type Path = Point[];

export interface ShapeInfo {
    idx  : number;
    name : string;

    coordinates : Path;
    price       : number;
    cost        : number;
    isActive    : boolean;
    
    polygonRef ?: any; 
    zIndex: number;
    
    isVisible   : boolean;
}

export class ZoneEdit {
    id: number;
    name: string; 
    is_active: boolean; 

    shapes: ShapeInfo[]; 

    price_km_flat:       number;
    cost_km_flat:        number;

    shift_costs_dataset: any[];
    vehicle_data ?: VehicleData[];

    get existingShapes() {
        return this.shapes?.filter(x => x.coordinates?.length );
    }

    /**
     * Dalla zona, costruisco un oggetto 
     * più facilmente gestibile ed integrabile nella UI.  
     * 
     * Le informazioni delle zone raggruppate in una singola chiave: "Shapes";
     * 
     * I prezzi per fascia oraria raggruppati in "shift_costs_dataset"
     * @param zone 
     * @returns 
     */
    static fromZone(zone: Zone){
        

        const shapes = Array.from(
            {length:5}, 
            (_, i) => zone?.getShapeInfo(i+1) 
        );

        const {
            id,
            name, 
            is_active,
            
            price_km_flat,
            cost_km_flat,

            vehicle_data
        } = zone; 

        return plainToClass(
            ZoneEdit, 
            {
                id,
                name, 
                shapes, 
                is_active,
                
                price_km_flat,
                cost_km_flat,

                shift_costs_dataset: zone.getGroupedShiftCosts(),
                vehicle_data
            }
        ) ;
    }

    /**
     * Dall'oggetto ZoneEdit ricavo un oggetto conforme alle API
     * @returns 
     */
    toZone(){
        const {
            id,
            name,
            shapes,
            ...other
        } = this;

        shapes.forEach(this.updateShapeCoordinates);
        
        const shapeFields = shapes.map(s => ({
            [`shape${s.idx}`]           : s.coordinates || null,
            [`price_shape${s.idx}`]     : s.price,
            [`cost_shape${s.idx}`]      : s.cost,
            [`is_shape${s.idx}_active`] : s.isActive,
        }))
        .reduce( (a, x) => {
            return {
                ...a,
                ...x
            }
        }, {});

        return {
            id, name,
            ...shapeFields,
            ...other,
            shift_costs: this.getShiftCosts()
        }; 
    }

    updateShapeCoordinates(shape: ShapeInfo){
        let newPath = shape.polygonRef
            ?.getPath()
            ?.getArray()
            .map(x => x.toJSON())
            .map(({ lat, lng }) => [lng, lat])

        if (newPath) {
            const [firstLng, firstLat]  = newPath[0];
            const [lastLng, lastLat]    = newPath[newPath.length-1];
    
            if ( firstLng !== lastLng || firstLat !== lastLat) {
                newPath = [
                    ...newPath,
                    newPath[0]
                ]
            }
        }

        shape.coordinates = newPath;
    }

    getShiftCosts(){
       return this.shift_costs_dataset.map(x => 

            Object.keys(x)
                  .filter( x => x.startsWith('weekDay'))
                  .map( key => {
                       return {
                            "shift_id"  : x.shift.id,
        
                            "weekday"   : x[key].weekday,
                            "hour_cost" : x[key].hour_cost,
                            "id"        : x[key].shift_cost_id,
                       } 
                  })
        
        )
        .flat();
    }

}

export interface ZoneRelation {
    id: number; 
    name: string; 
}

export interface ZoneCustomPricing {
    id?: number;
    zone_id: number;
    entity_id?: number;
    
    price_shape1?:  number;
    price_shape2?:  number;
    price_shape3?:  number;
    price_shape4?:  number;
    price_shape5?:  number;

    cost_shape1?: number;
    cost_shape2?: number;
    cost_shape3?: number;
    cost_shape4?: number;
    cost_shape5?: number;

    price_km_flat?:       number;
    cost_km_flat?:        number;

    /**
     * NON USARE
     */
    zone?: Zone;
}