import { UserRoleEnum } from '@/model/User';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { PlanningRoutesEnum }                      from './PlanningRoutesEnum';

const PlanningWW  = () => import(
    /* webpackChunkName: "Planning-module" */
    '../pages/PlanningWhenWhere/PlanningWhenWhere.vue'
);
const PlanningTimePage  = () => import(
    /* webpackChunkName: "Planning-module" */
    '../pages/Planning/PlanningPage.vue'
);

const PlanningTrackFiltersPage  = () => import(
    /* webpackChunkName: "Planning-module" */
    '../pages/PlanningTrackFilters/PlanningTrackFiltersPage.vue'
);
const PlanningTrackPage  = () => import(
    /* webpackChunkName: "Planning-module" */
    '../pages/PlanningTrack/PlanningTrackPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path      : 'planning/time',
        name      : PlanningRoutesEnum.PLANNING_PREFIX,
        component : PlanningWW,
        meta      : {
			tabTitle: 'Pianificazione ordini',
			breadCrumbItems: [
				{ label: 'Quando e dove?' },
			],
            roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
        }
    },
    {
        path     : '/planning/time/:zoneId/:date/:shiftId/',
        name     : PlanningRoutesEnum.PLANNING_TIME,
        component: PlanningTimePage,
        props    : ( to: RouteLocationNormalized ) => {
            const { zoneId, date, shiftId } = to.params;

            return {
                zoneId : +zoneId,
                shiftId: +shiftId,
                date   : new Date( date as string )
            }
        },
        meta     : {
            tabTitle       : 'Pianificazione ordini',
            breadCrumbItems: [
                { label: 'Assegnazioni interne' },
            ],
            roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
        }
    },


    {
        path      : '/planning/track',
        name      : PlanningRoutesEnum.PLANNING_TRACK_FILTERS,
        component : PlanningTrackFiltersPage,
        meta      : {
			tabTitle: 'Pianificazione tracciato',
			breadCrumbItems: [
				{ label: 'Pianificazione Tracciato - Filtri' },
			],
            roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
    },
    {
        path     : '/planning/track/:zoneId/:date/:shiftId/:takerId',
        name     : PlanningRoutesEnum.PLANNING_TRACK,
        props    : ( to: RouteLocationNormalized ) => {
            const { zoneId, date, shiftId, takerId } = to.params;

            return {
                zoneId  : +zoneId,
                shiftId : +shiftId,
                takerId : +takerId,
                date    : new Date( date as string ),
                readonly: to.query.readonly === 'true'
            }
        },
        component: PlanningTrackPage,
        meta     : {
            tabTitle       : 'Pianificazione tracciato',
            breadCrumbItems: [
                { label: 'Pianificazione Tracciato' },
            ],
            roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
        }
    },
];

export { routes };
export { PlanningRoutesEnum };
