import { GetterTree }    from "vuex";
import { OrdersState }   from "./state";
import { createGetters } from "@/store/common";
import { AppState }      from "@/store/types";
import { NewOrder }      from "@/model/Order";
import { Customer }      from "@/model/Customer";
import { Path } from "@/model/Zone";

export interface Getters {
    newOrder: NewOrder;

    lastShapeFromZone: Path;

    customer: Customer;

    withParams(param: any): any;
}

const getters: GetterTree<OrdersState, AppState> = {
    ...createGetters(
        "newOrder"
    ),

    customer({ newOrder }) {
        return newOrder?.customer;
    },

    withParams: (state) => (param: any) => {
        return "withParams";
    },

    lastShapeFromZone({ newOrder }) {
        let last;

        for (let i = 5; i >= 1; i--) {
            const s: Path = newOrder?.zone[`shape${i}`];
            const active =  newOrder?.zone[`is_shape${i}_active`]

            if (s?.length && active) {
                last = s;
                break;
            }
        }

        return last;
    }
}

export default getters;