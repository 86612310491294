
  import { defineComponent } from "vue";
  import { useGlobalDialog } from "@plugins/Dialog-plugin";

  export default defineComponent({
    computed: {
      isLoading() {
        return this.$config.isLoading;
      },
    },

    setup() {
      return {
        ...useGlobalDialog(),
      };
    },
  });
