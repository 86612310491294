import { computed, defineComponent, getCurrentInstance, h, Plugin, readonly, Ref, ref, resolveComponent } from 'vue';
import { Deferred } from '@sharedUtils/utils';

const PromisedDialog = defineComponent({
    name: 'promise-dialog',
    setup(props){
        const {
            globalDialogComponent,
            globalDialogConfig,
            displayGlobalDialog
        } = useGlobalDialog();

        const pDialog = resolveComponent('PDialog');

        return () => {

            const config = globalDialogConfig.value;

            const pDialogProps = {
                style              : {width: '450px'},
                'visible'          : displayGlobalDialog.value,
                'onUpdate:visible' : (e) => displayGlobalDialog.value = e,
                modal              : true,
                class              : 'p-fluid',
                draggable          : true,
                dismissableMask    : true,
                keepInViewPort     : true,
                minX               : 0,
                minY               : 0,
                showHeader         : !!config?.header,
                ...config
            };

            const pDialogSlots = {
                default: (props) => h(globalDialogComponent.value, props),
                //   header: (props) => h('h1', props, "ciao")
            }

            return h(
                // @ts-ignore
                pDialog,
                pDialogProps,
                pDialogSlots
            )
        }
            
            
    }
})

declare module 'vue' {
    interface ComponentCustomProperties {
        /**
         * display or hide global dialog
         */
        readonly $showGlobalDialog: Ref<boolean>;

        /**
         * component to render inside dialog
         */
        readonly $globalDialogComponent: Ref<any>;

        /**
         * set true $showGlobalDialog
         * and set component to show
         */
        readonly $showDialog: (component: any, config?: any) => Promise<any>;

        /**
         * reset everything and hide current dialog
         */
        readonly $hideDialog: () => Promise<any>;
    }
}

const DialogPlugin: Plugin = {
    install(app) {
        console.debug("Installing dialog plugin...");

        app.component('PromisedDialog', PromisedDialog);

        app.config.globalProperties.$showGlobalDialog       = ref(false);
        app.config.globalProperties.$globalDialogComponent  = ref(null);
        app.config.globalProperties.$globalDialogConfig     = ref(null);
        app.config.globalProperties.$deferredDialogPromise  = ref(false);


        app.config.globalProperties.$showDialog =
            function(component: any, config ) {
                console.debug("config", config);
                const { globalProperties: gp} = app.config; 
                const d = new Deferred(); 

                gp.$globalDialogComponent.value = component; 
                gp.$globalDialogConfig.value    = config; 

                gp.$showGlobalDialog.value = true;
                gp.$deferredDialogPromise  = d;
                
                return d.promise;
            };

        app.config.globalProperties.$hideDialog =
            function(response: any) {
                const { globalProperties: gp} = app.config; 
                
                gp.$deferredDialogPromise.
                    resolve(response);

                gp.$showGlobalDialog.value = false;

                gp.$globalDialogComponent.value = null; 
            };

    }
};

export default DialogPlugin;


export function useGlobalDialog() {

    const instance = getCurrentInstance()

    const displayGlobalDialog = computed<any>({
        get() {
            // @ts-ignore
            return instance.appContext.config.globalProperties
                .$showGlobalDialog.value; 
        },
        set(value) {
            if (!value) {
                // @ts-ignore
                instance.appContext.config.globalProperties.$hideDialog();
            } else {
                // @ts-ignore
                instance.appContext.config.globalProperties
                    .$showGlobalDialog.value = value; 
            }
        }
    });

    const globalDialogComponent = computed<any>( () => {
        // @ts-ignore
        return instance.appContext.config.globalProperties
            .$globalDialogComponent.value;
    });

    const globalDialogConfig = computed<any>(() => 
        instance.appContext.config.globalProperties
            .$globalDialogConfig.value
    )

    return {
        displayGlobalDialog,
        globalDialogComponent,
        globalDialogConfig
    }
}