import { ref } from "vue";
import Pusher  from "pusher-js";

import { configuration } from "@plugins/Config-plugin";

Pusher.logToConsole = process.env.NODE_ENV !== "production";

export const $pusher = ref<Pusher>( null );

export function createPusher( token: string ) {
    if (!token) {
        throw("No token provided");
    }

    if (!$pusher.value) {
        console.debug( "Connect pusher instance" );

        const p = new Pusher( configuration.pusherKey, {
            cluster     : 'eu',
            authEndpoint: `${ configuration.apiBaseUrl }/broadcasting/auth`,
            auth        : {
                headers: {
                    "Authorization": `Bearer ${ token }`
                }
            },
        } )

        $pusher.value = p;

        handleLimitError( p );
    }
}

function handleLimitError( pusher: Pusher ) {
    pusher.connection.bind( "error", ( error ) => {
        if (error?.data.code === 4004) {
            console.error( 'Pusher connection limit reached.' );
        } else {
            console.error( error?.data?.message );
        }

    } )
}


export function subscribeChannel( channel: Channel ) {
    const c = $pusher.value.subscribe( `private-${ channel }` )

    c.bind( "pusher:subscription_succeeded", () => {
        console.info( `Subscribed to channel: ${ channel }` );
    } )

    c.bind( "pusher:subscription_error", ( error ) => {
        console.error( `Subscription error for channel:  ${ channel }`, error );
    } )

    return c;
}

export function addEventListner(
    channel: Channel,
    eventName: PusherEventsEnum,
    callback: Function
) {
    return subscribeChannel( channel ).bind( eventName, callback )
}

export function onError( channel: Channel, onErrorCallback: Function ) {
    return addEventListner( channel,
        PusherEventsEnum.ON_AUTH_ERROR,
        onErrorCallback
    )
}

export function resetPusher() {
    $pusher.value.unbind_all();
    $pusher.value.disconnect();
    $pusher.value = null;
}

type Channel = PusherChannelEnum | string;

export const enum PusherChannelEnum {
    CHAT_ADMIN = "chat.admin",
}

export const enum PusherEventsEnum {
    ON_AUTH_ERROR = "subscription_error",
    COUNT_CHANGE  = "unread-count-change",
    MESSAGE_SENT  = "message-sent",
}
