import { Shift }                                      from "@/model/Shift";
import { Vehicle }                                    from "@/model/Vehicle";
import { NewOrderPlugin }                             from "@/modules/orders/store/NewOrderPlugin";
import { shiftsService }                              from "@services/shift.service";
import { vehiclesService }                            from "@services/vehicles.service";
import { InjectionKey }                               from "vue";
import { createLogger, createStore, Store, useStore } from "vuex";

import { AppState } from "./types";
import { Kpi }      from "@services/kpi.service";

export const key_store: InjectionKey<Store<AppState>> = Symbol()

export function useAppStore() {
  return useStore( key_store );
}

export default createStore<AppState>({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    timeslots: null,

    vehicles: null,

    kpi: null,
    kpiParams: null,
  },

  mutations: {
    setTimeslots(state, data: Shift[]) {
      state.timeslots = data;
    },
    setVehicles( state, data: Vehicle[] ) {
      state.vehicles = data;
    },
    setKpi<T extends Kpi>( state, data: T ) {
      state.kpi = data;
    },
    setKpiParams<T extends Kpi>( state, data: T ) {
      state.kpiParams = data;
    },
    cleanKpi( state ) {
      state.kpi       = null;
      state.kpiParams = null;
    },
  },

  getters: {
    vehicles({ vehicles }) {
      return vehicles;
    },

    timeslots({ timeslots }) {
      return timeslots;
    },

    timeslotsLabelValue({ timeslots }): { label: string, value: number }[] {
      function hhmm(time: string) {
        return time.substring(0, time.length - 3);
      }

      return timeslots?.map(x => ({
        label: `${x.name} - ${hhmm(x.start_time)} | ${hhmm(x.end_time)}`,
        value: x.id,
        endTime: x.end_time,
      }));
    },

    shiftById: ({ timeslots }) => (id: number) => {
      return timeslots?.find(x => x.id === id);
    }
  },

  actions: {
    async loadShifts({ state, commit }) {
      if(!state.timeslots?.length) {
        const response  = await shiftsService.getAll();
        commit('setTimeslots', response);
      }

      return;
    },
    async loadVehicles({ state, commit }) {
      if (!state.vehicles?.length) {
        const vs = await vehiclesService.getAll();
        commit('setVehicles', vs);
      }

      return;
    }
  },

  plugins: process.env.NODE_ENV !== 'production'
    ? [
      createLogger(),
      NewOrderPlugin
    ]
    : [
      NewOrderPlugin
    ]
})

