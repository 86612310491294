import { UserRoleEnum } from '@/model/User';
import { RouteRecordRaw } from 'vue-router';
import { BillingRoutesEnum } from './BillingRoutesEnum';

const BillingCustomersFormerPage  = () => import(
	/* webpackChunkName: "billing-customers" */
	'../pages/billing-customers-former/BillingCustomersFormerPage.vue'
);
const BillingFindCustomersPage  = () => import(
	/* webpackChunkName: "billing-customers" */
	'../pages/billing-find-customers/BillingFindCustomersPage.vue'
);
const BillingCustomerPage  = () => import(
	/* webpackChunkName: "billing-customers" */
	'../pages/billing-customer/BillingCustomerPage.vue'
);


const BillingSuppliersFormerPage  = () => import(
	/* webpackChunkName: "billing-suppliers" */
	'../pages/billing-suppliers-former/BillingSuppliersFormerPage.vue'
);
const BillingFindSuppliersPage  = () => import(
	/* webpackChunkName: "billing-suppliers" */
	'../pages/billing-find-suppliers/BillingFindSuppliersPage.vue'
);
const BillingSupplierPage  = () => import(
	/* webpackChunkName: "billing-suppliers" */
	'../pages/billing-supplier/BillingSupplierPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
	// #region Suppliers and Takers routes
	{
		path: '/billing/suppliers',
		name: BillingRoutesEnum.BILLING_SUPPLIERS,
		component: BillingSuppliersFormerPage,
		meta: {
			tabTitle: 'Lista movimenti',
			breadCrumbItems: [
				{ label: 'Lista movimenti' },
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
	{
		path: '/billing/suppliers/find',
		name: BillingRoutesEnum.BILLING_SUPPLIERS_FIND,
		component: BillingFindSuppliersPage,
		props: true,
		meta: {
			tabTitle: 'Ricerca Fornitori/Taker',
			breadCrumbItems: [
				{
					label: 'Lista movimenti',
					to: { name:  BillingRoutesEnum.BILLING_SUPPLIERS }
				},
				{
					label: 'Ricerca Fornitori/Taker',
				},
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
	{
		path: '/billing/suppliers/:entityId/:from/:to/:zoneId(\\d+)?',
		name: BillingRoutesEnum.BILLING_SUPPLIERS_NEW,
		component: BillingSupplierPage,
		props: true,
		meta: {
			tabTitle: 'Report Fornitore/Taker',
			breadCrumbItems: [
				{
					label: 'Lista movimenti',
					to: { name:  BillingRoutesEnum.BILLING_SUPPLIERS }
				},
				{
					label: 'Ricerca Fornitori/Taker',
					to: { name:  BillingRoutesEnum.BILLING_SUPPLIERS_FIND }
				},
				{ label: 'Report Fornitore/Taker' },
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
	//#endregion

	// #region Customer routes
	{
			path: '/billing/customers',
			name: BillingRoutesEnum.BILLING_CUSTOMERS,
			component: BillingCustomersFormerPage,
			meta: {
				tabTitle: 'Lista movimenti',
				breadCrumbItems: [
					{ label: 'Lista movimenti' },
				],
				roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
			}
	},
	{
		path: '/billing/customers/find',
		name: BillingRoutesEnum.BILLING_CUSTOMERS_FIND,
		component: BillingFindCustomersPage,
		props: true,
		meta: {
			tabTitle: 'Ricerca Clienti',
			breadCrumbItems: [
				{
					label: 'Lista movimenti',
					to: { name:  BillingRoutesEnum.BILLING_CUSTOMERS }
				},
				{ label: 'Ricerca Clienti' },
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	},
	{
		path: '/billing/customers/:entityId/:from/:to/:zoneId(\\d+)?',
		name: BillingRoutesEnum.BILLING_CUSTOMERS_NEW,
		component: BillingCustomerPage,
		props: true,
		meta: {
			tabTitle: 'Report Clienti',
			breadCrumbItems: [
				{
					label: 'Lista movimenti',
					to: { name:  BillingRoutesEnum.BILLING_CUSTOMERS }
				},
				{
					label: 'Ricerca Clienti',
					to: { name:  BillingRoutesEnum.BILLING_CUSTOMERS_FIND }
				},
				{ label: 'Report Cliente' },
			],
			roles: [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.TMT_OPERATOR]
		}
	}
	//#endregion
];

export { routes };
export { BillingRoutesEnum };
