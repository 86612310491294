import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "auto-layout": "",
    value: _ctx.fakeData
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Skeleton, {
          width: "4rem",
          height: "2rem"
        }),
        _createVNode(_component_Skeleton, {
          width: "10rem",
          height: "2rem"
        })
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(+_ctx.columns, (a) => {
        return (_openBlock(), _createBlock(_component_Column, {
          key: `loading_${a}`
        }, {
          header: _withCtx(() => [
            _createVNode(_component_Skeleton)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_Skeleton)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["value"]))
}