import { IBaseApiModel } from '@/model/common/BaseApiModel';
import { BaseApiFilter } from '@/model/filters/BaseApiFilter';
import { PaginatedResponse } from '@/model/PaginatedResponse';
import axios, { CancelTokenSource } from 'axios';
import { HttpService } from './http.service';

export abstract class  CRUD<T extends IBaseApiModel, F extends BaseApiFilter> extends HttpService {

    protected readonly endPoint: string;

    protected source: CancelTokenSource;

    protected getCleanParams(params?: F) {
        if (!params){
            params = (new BaseApiFilter() as F);
        }
        
        params = JSON.parse(JSON.stringify(params));

        const { filters } = params; 
        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k].value; 
                const n = filters[k].nullable; 
    
                if ((Array.isArray(v) && v.length === 0)
                ||  (!v && !n)) {
                    delete filters[k];
                }
            });
    
            if (Object.keys(filters).length === 0) {
                delete params.filters; 
            }
        }

        return params;
    }

    public index(args?: F){
        const params      = this.getCleanParams(args);
        const cancelToken = this.generateCancelToken();

        return this.get<PaginatedResponse<T>>(
            `${this.endPoint}`, 
            { params, cancelToken }
        );
    }

    public getById(id: number): Promise<T>{
        return this.get<T>(`${this.endPoint}/${id}`);
    }

    public getAll(): Promise<T[]>  {
        return this.get<T[]>(`${this.endPoint}`);
    }

    public create(item: T): Promise<T>{
        return this.post<T>(`${this.endPoint}`, item);
    }
    
    public upsertAll(item: T[]): Promise<T[]>{
        return this.put<T[]>(`${this.endPoint}`, item);
    }

    public update(item: Partial<T>): Promise<T>{
        return this.put<T>(`${this.endPoint}/${item.id}`, item);
    }
    public updatePatch(item: Partial<T>): Promise<T>{
        return this.patch<T>(`${this.endPoint}/${item.id}`, item);
    }

    public remove(item: T): Promise<T>{
        return this.delete<T>(`${this.endPoint}/${item.id}`, item);
    }
    
    public deleteBySystem(item: T): Promise<T>{
        return this.delete<T>(`${this.endPoint}/${item.id}`, item);
    }

    public removeMany(ids: number[]): Promise<T>{
        return this.delete<T>(`${this.endPoint}`, ids);
    }

    protected generateCancelToken() {
        this.source = axios.CancelToken.source();
        return this.source.token;
    }

    public cancelPendingRequests(){
        this.source?.cancel();
    }
}