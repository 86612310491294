import { Vehicle } from '@/model/Vehicle';
import { CRUD } from './base/crud';

class VehiclesService extends CRUD<Vehicle, any>{
    readonly endPoint = `admin/vehicles`;
}

export const vehiclesService = new VehiclesService();


