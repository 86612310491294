import { Plugin, ref } from 'vue';

class Configuration {

    _isLoading = ref( false );

    get isLoading(){
        return this._isLoading.value;
    }
    set isLoading(v){
        this._isLoading.value = v;
    }

    get dateFormat(): string {
        return this._get("DATE_FORMAT");
    }

    get primevueDateFormat(): string {
        return this._get("PRIMEVUE_DATE_FORMAT");
    }

    get appVersion(): string {
        return this._get("VERSION");
    }

    get appTitle(): string {
        return this._get("TITLE");
    }

    get apiBaseUrl(): string {
        return this._get("API_BASE_URL");
    }

    get rootFolderPath() {
        return this._get("ROOT_FOLDER_PATH");
    }

    get mapApiKey(){
        return this._get("MAP_API_KEY");
    }

    get mapVersion(){
        return this._get("MAP_VERSION");
    }

    get geocodingUrl(){
        return this._get("MAP_GEOCODING_API");
    }

    get startZoom(){
        return +this._get("MAP_START_ZOOM");
    }

    get startCenter() {
        const lat = +this._get( "MAP_START_LAT" );
        const lng = +this._get( "MAP_START_LNG" );
        return { lat, lng };
    }

    get stripeKey() {
        return this._get( "STRIPE_KEY" );
    }

    get pusherKey() {
        return this._get( "PUSHER_KEY" );
    }

    get loginImage(){
        return this._get("LOGIN_IMAGE")
    }
    get loginLogos(){
        return this._get("LOGIN_LOGOS").split(',')
    }
    get appLogo() {
        return this._get("LOGO")
    }

    get badgeEnabled() {
        return this._get( "BADGE_ENABLED" ) == "true";
    }

    private _get( name: string ): string {
        return process.env[`VUE_APP_${ name }`];
    }

}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $config: Configuration;
    }
}

export const configuration = new Configuration();

const ConfigPlugin: Plugin = {
    install(app) {
        console.debug("Installing config plugin...");

        app.config.globalProperties.$config = configuration;
    }
};

export default ConfigPlugin;
