import { Plugin } from 'vue';

import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

/**
 * Quei geni di PrimeVue hanno deciso finalmente di tipizzare questi metodi 
 * Beh, ovviamente hanno dimenticato qualcosa. 
 * "header" in questo caso lo aggiungo alla definizione tipo, in attesa
 * che i nostri amati geni correggano
 */
declare module "primevue/confirmationoptions" {
    interface ConfirmationOptions {
        header?: string;
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Success message.   
         * In generally a green toast
         */
        readonly $successMessage: (detail: string, summary?: string) => void;

        /**
         * Info message.   
         * In generally a blue toast
         */
        readonly $infoMessage: (detail: string, summary?: string) => void;

        /**
         * Error message.  
         * In generally a red toast 
         */
        readonly $errorMessage: (detail: string, summary?: string, life?: number) => void;


        readonly $confirmMessage: (detail: string, summary?: string) => Promise<boolean>;
    }
}

const MessagesPlugin: Plugin = {
    install(app) {
        console.debug("Installing messages plugin...");

        app.config.globalProperties.$successMessage =
            function (detail: string, summary: string = "Operazione completata") {
                this.$toast.add({
                    severity: 'success',
                    summary,
                    detail,
                    life: 3000
                });
            };

        app.config.globalProperties.$infoMessage =
            function (detail: string, summary: string = "Attenzione") {
                this.$toast.add({
                    severity: 'info',
                    summary,
                    detail,
                    life: 3000
                });
            };

        app.config.globalProperties.$errorMessage =
            function (
                detail: string, 
                summary: string = "Operazione interrotta",
                life: number = 3000
            ) {
                this.$toast.add({
                    severity: 'error',
                    summary,
                    detail,
                    life
                });
            }


        app.config.globalProperties.$confirmMessage =
            function (message: string, header: string = "Conferma cancellazione") {
                return new Promise((resolve, reject) => {

                    this.$confirm.require({
                        message, header,
                        icon: 'pi pi-exclamation-triangle',
                        acceptClass: 'p-button-danger',
                        accept: () => {
                            resolve(true);
                        },
                        reject: () => {
                            resolve(false);
                        }
                    });

                });

            }
    }
};

export default MessagesPlugin;

export function useMessage() {
    const {
        add
    } = useToast();

    const {
        require
    } = useConfirm();


    function successMessage(
        detail: string,
        summary: string = "Operazione completata"
    ) {
        add({
            severity: 'success',
            summary,
            detail,
            life: 3000
        });
    }

    function errorMessage(
        detail: string,
        summary: string = "Operazione interrotta"
    ) {
        add({
            severity: 'error',
            summary,
            detail,
            life: 3000
        });
    }

    function errorMessageLeft(
        detail: string,
        summary: string = "Operazione interrotta"
    ) {
        add({
            severity: 'error',
            summary,
            detail,
            life: 3000,
            group: 'top-left'
        });
    }

    function confirmMessage(
        message: string,
        header: string = "Conferma cancellazione"
    ) {
        return new Promise(
            (resolve) => {
                require({
                    message, header,
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        resolve(true);
                    },
                    reject: () => {
                        resolve(false);
                    }
                });
            }
        );
    }

    return {
        successMessage,
        errorMessage,
        errorMessageLeft,
        confirmMessage
    }
}
